<template>
  <div class="login">
    <template v-if="!token">
      <div class="wrapper">
        <p>打开微信扫码登录</p>
        <img :src="data.url" v-if="data" />
        <p class="qr-countdown-tip">
          二维码<span>{{ qrCodeSecond }}秒</span>后刷新
        </p>
      </div>
    </template>
    <template v-else>
      <div class="content">
        <p class="title">请选择要开播的账号</p>
        <ul class="buyin-account-list">
          <li
            class="block"
            :class="{ __selected: item.selected }"
            v-for="(item, $index) of accounts"
            :key="$index"
            @click="handleAccountClick(item)"
          >
            <div class="avatar"></div>
            <div class="block-info">
              <p class="nickname">{{ item.nickname || '未配置' }}</p>
              <p class="dy-no">
                抖音号: <span>{{ item.douyinid }}</span>
              </p>
            </div>
            <van-icon v-if="item.selected" name="checked" color="#198afa" />
          </li>
        </ul>
        <div class="content-btns">
          <van-button class="btn-start" :disabled="!account" type="info" @click="confirmAccount">开播</van-button>
          <van-button class="btn-logout" plain type="danger" @click="logout">更换账号</van-button>
        </div>
      </div>
    </template>
    <div class="footer">
      <div class="logo">
        <div class="img"></div>
        <p>
          小竹猫<span v-if="this.version.code > 0">{{ this.version.name }}({{ this.version.code }})</span>
        </p>
      </div>
      <div class="device">{{ deviceId }}</div>
    </div>
  </div>
</template>
<script>
import request from '@/libs/request'
import { Dialog, Notify } from 'vant'

// 二维码过期时间(秒)
const QR_CODE_LIVE = 5 * 60

const TOKEN_KEY = 'xiaozhumao_token'
const LIVE_ID_KEY = 'xiaozhumao_live_id'
const DEVICE_ID_KEY = 'xiaozhumao_device_id'

const LIVE_TO_DEVICE = {
  1: 38,
  4: 10,
  5: 8,
  6: 5,
  7: 9,
  8: 2,
  9: 36,
  10: 36,
  11: 36,
  12: 7,
  13: 13,
  14: 36,
  15: 36,
  16: 36,
  17: 45,
  18: 4,
  19: 11,
  20: 3,
  21: 6,
  22: 12,
  23: 36,
  24: 44,
  25: 37,
  26: 36,
  27: 14,
  28: 39
}

export default {
  data() {
    const token = localStorage.getItem(TOKEN_KEY)
    return {
      token: token,
      deviceId: null,
      updateSetting: {},
      version: {
        name: 'DEV',
        code: -1
      },
      qrCodeSecond: QR_CODE_LIVE,
      data: {},
      accounts: [],
      account: null
    }
  },
  async created() {
    localStorage.removeItem('__should_start__')
    
    this.getDeviceId()
    this.getVersion()

    const shouldUpdate = await this.checkUpdate()

    if (shouldUpdate) {
      this.runUpdate()
      return
    }

    const liveId = localStorage.getItem(LIVE_ID_KEY)

    let deviceId

    if (liveId) {
      localStorage.removeItem(LIVE_ID_KEY)
      const liveIdNumber = parseInt(liveId)
      if (liveIdNumber in LIVE_TO_DEVICE) {
        deviceId = LIVE_TO_DEVICE[liveIdNumber]
        localStorage.setItem(DEVICE_ID_KEY, deviceId)
      }
    } else {
      deviceId = localStorage.getItem(DEVICE_ID_KEY)
    }

    if (deviceId) {
      this.$router.push({
        name: 'Live',
        params: {
          id: deviceId
        }
      })
      return
    }

    if (this.token) {
      try {
        await this.fetchAccounts()
        log('登录成功')
      } catch (err) {
        log('自动登录失败，尝试重新登录')
        this.token = null
        localStorage.clear()
        await this.doLogin()
        await this.fetchAccounts()
      }
    } else {
      await this.doLogin()
      await this.fetchAccounts()
      log('登录成功')
    }
  },
  methods: {
    async logout() {
      localStorage.clear()
      await this.$nextTick()
      location.reload()
    },
    async checkUpdate() {
      if (this.version.name.indexOf('DEV') !== -1) {
        return
      }
      if (this.version.code < 0) {
        return
      }
      try {
        const res = await this.$http.get('/api/last-setting')
        log('检查更新', res.data)
        this.updateSetting = res.data
        const remoteVersionCode = parseInt(this.updateSetting.code)
        return remoteVersionCode > this.version.code
      } catch (err) {
        log('检查更新失败', err.message)
      }
      return false
    },
    runUpdate() {
      log('开始更新')

      Dialog({
        message: `检测到新版本 ${this.updateSetting.version}(${this.updateSetting.code}) \r\n正在更新`,
        showConfirmButton: false,
        showCancelButton: false
      })

      setTimeout(() => {
        window.__bridge__.installApp(this.updateSetting.apk)
      }, 3000)
    },
    getVersion() {
      log('开始获取版本号', navigator.userAgent)
      const ua = navigator.userAgent
      const result = ua.match(/xiaozhumao\/([^\s]+)\s\(Code\s(\d+)\)/im)

      if (!result) {
        return
      }

      const [full, name, code] = result

      this.version.name = name
      this.version.code = parseInt(code)

      window.__APP_VERSION__ = this.version
      log('本地版本', window.__APP_VERSION__)
    },
    getDeviceId() {
      log('开始获取设备ID')
      if (!window.__bridge__ || !window.__bridge__.getDeviceId) {
        log('客户端未提供获取设备ID接口')
        return
      }
      this.deviceId = window.__bridge__.getDeviceId()
      window.__DEVICE_ID__ = this.deviceId
      log('设备ID', this.deviceId)
    },
    doLogin() {
      clearInterval(this.loginCheckTimmer)
      this.checking = false
      return new Promise(async (resolve) => {
        const startAt = Date.now()

        this.data = await this.getQRCode()

        if (!this.data) {
          return
        }

        this.qrCodeSecond = QR_CODE_LIVE

        let timeGap = 0

        const loop = async () => {
          const now = Date.now()
          const countDown = Math.ceil((QR_CODE_LIVE * 1000 - (now - startAt)) / 1000)

          if (countDown <= 0) {
            log('二维码超时,更新二维码')
            this.qrCodeSecond = 0
            this.doLogin()
            return
          }

          this.qrCodeSecond = countDown

          // 每隔2秒获取一次二维码的状态
          const diff = Math.floor((QR_CODE_LIVE - countDown) / 2)

          if (timeGap === diff || this.checking) {
            return
          }

          timeGap = diff

          let token

          try {
            this.checking = true
            token = await this.checkCode()
            this.checking = false
          } catch (err) {
            log('二维码状态异常', err.message, err.response.data)
            this.doLogin()
            return
          }

          if (!token) {
            return
          }

          log('登录成功', token)
          clearInterval(this.loginCheckTimmer)

          this.token = token
          request.setToken(token)
          localStorage.setItem(TOKEN_KEY, this.token)

          Notify({ type: 'success', message: '登录成功' })

          setTimeout(resolve, 100)
        }

        this.loginCheckTimmer = setInterval(loop, 200)
      })
    },
    async getQRCode() {
      try {
        const res = await this.$http.post('/api/auth/login-qrcodes')
        return res.data
      } catch (err) {
        log('获取二维码异常:', err.message)
        Notify({ type: 'danger', message: '获取二维码失败，请检查网络' })
        return null
      }
    },
    async checkCode() {
      const res = await this.$http.post('/api/auth/login', {
        code: this.data.code
      })
      if (res.data && res.data.token) {
        return res.data.token
      }
      return null
    },
    async fetchAccounts() {
      const res = await this.$http.get('/api/devices')
      Notify({ type: 'primary', message: '获取达人信息成功' })
      this.accounts = res.data.map((item) => {
        return Object.assign(item, {
          selected: false
        })
      })
    },
    async fetchLiveConfig(accountId) {
      const res = await this.$http.get(`/api/devices/${accountId}/strapi-live-config`)
      return res.data
    },
    async confirmAccount() {
      try {
        const liveConfig = await this.fetchLiveConfig(this.account.id)

        if (!liveConfig) {
          Notify({ type: 'danger', message: '该达人未配置直播计划' })
          return
        }

        localStorage.setItem(DEVICE_ID_KEY, this.account.id)
        Notify({ type: 'success', message: '已保存选中的计划' })
        this.$router.push({
          name: 'Live',
          params: {
            id: this.account.id
          }
        })
      } catch (err) {
        if (err && err.response && err.response.status === 404) {
          Notify({ type: 'danger', message: '该达人可能未配置直播计划' })
          return
        }
        Notify({ type: 'danger', message: '获取直播计划失败 ' + err.message })
        log('获取直播计划失败', err.message, this.account.id)
      }
    },
    handleAccountClick(account) {
      if (this.account) {
        this.account.selected = false
      }
      account.selected = true
      this.account = account
    }
  }
}
</script>
<style lang="scss">
.btn-login {
  margin: 0 auto;
  display: block;
}

.wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #010101;
  font-size: 0.5rem;
  padding-top: 3rem;
  font-weight: bolder;

  .qr-countdown-tip {
    font-weight: normal;
    font-size: 0.24rem;
    margin-top: 0.2rem;
    color: #a9a9a9;
    span {
      display: inline-block;
      padding: 0 0.1rem;
      font-size: 0.3rem;
      color: #198afa;
      width: 1rem;
      text-align: center;
    }
  }

  img {
    display: block;
    width: 5rem;
    height: 5rem;
    margin-top: 0.8rem;
  }
}

.content {
  box-sizing: border-box;
  padding-top: 2rem;

  .title {
    font-size: 0.26rem;
    text-align: center;
  }

  .sub-title {
    font-size: 0.22rem;
    text-align: center;
  }

  .buyin-account-list {
    margin: 0 auto;
    margin-top: 1.4rem;
    font-size: 0.3rem;
    height: 3.7rem;
    width: 5rem;
    overflow-x: hidden;
    overflow-y: scroll;

    .block {
      border-radius: 0.1rem;
      border: 1px solid #e3e3e3;
      box-sizing: border-box;
      display: flex;
      padding: 0.2rem;
      box-sizing: border-box;
      position: relative;

      &:nth-child(n + 2) {
        margin-top: 0.3rem;
      }

      &.__selected {
        border: 1px solid #198afa;
        color: #198afa;
      }

      .avatar {
        width: 1rem;
        height: 1rem;
        border-radius: 0.1rem;
        overflow: hidden;
        background: url('./assets/default-profile.png') no-repeat 0 0;
        background-size: cover;
      }

      .block-info {
        margin-left: 0.3rem;

        .nickname {
          font-weight: bolder;
          font-size: inherit;
        }

        .dy-no {
          font-size: 0.24rem;
          margin-top: 0.2rem;
          color: #198afa;
        }
      }

      .van-icon {
        position: absolute;
        top: 0.6rem;
        right: 0.4rem;
      }
    }
  }

  .content-btns {
    display: flex;
    justify-content: center;
    button {
      width: 2rem;
      &:nth-child(n + 2) {
        margin-left: 0.3rem;
      }
    }
    .btn-logout {
      color: #a9a9a9;
      border: 0;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 0.3rem;
  left: 0;
  width: 100%;
  font-size: 0.3rem;

  .logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.3rem;
    font-weight: bolder;

    .img {
      width: 1rem;
      height: 1rem;
      background: url('./assets/logo.png') no-repeat 0 0;
      background-size: cover;
    }

    span {
      display: inline-block;
      padding: 0 0.03rem;
      font-size: 0.2rem;
    }
  }

  .device {
    font-size: 0.24rem;
  }
}
</style>
