<template>
  <div class="app-page app-page_live" style="user-select: none">
    <!-- <button @click="handleTest" style="position: absolute; top: 100px; left: 100px; z-index: 9999">测试</button> -->
    <!-- 组件列表 -->
    <component
      v-for="item of assets"
      :data-type="item.type"
      :state="state"
      :key="item._id"
      :data="item"
      :is="COMPONENTS[item.type]"
      @decodeError="handleMediaDecodeError"
      @timeUpdate="handleMediaTimeUpdate"
      @indexUpdate="handleComponentIndexUpdate(item, ...arguments)"
    ></component>

    <!-- 双击层 -->
    <div class="touch-layer" @touchstart="handleTouchStart"></div>

    <!-- 权限获取提示面板 -->
    <van-dialog
      v-model="isPermissionPannelVisible"
      class="permission-pannel"
      :show-confirm-button="false"
      :close-on-click-overlay="true"
    >
      <p class="title">获取【{{ currentPermission.label }}】权限</p>
      <p class="desc">{{ currentPermission.desc }}</p>
      <img v-if="currentPermission.img" :src="currentPermission.img" />
      <van-button class="permission-pannel_confirm" type="info" @click="handlePermissionPannelConfirm"
        >去授权</van-button
      >
    </van-dialog>

    <template v-if="isMenuVisible">
      <!-- 右上角操作菜单 -->
      <div class="ctrl-menus">
        <div class="ctrl-btn" @click="handleLiveBtnClick">
          <van-icon :name="state === 0 ? 'music-o' : 'stop-circle'" />
          <span v-if="state === 0">开播</span>
        </div>
        <template v-if="0 === this.state">
          <div class="ctrl-btn" @click="handleHomeBtnClick">
            <van-icon name="home-o" />
            <span>首页</span>
          </div>
          <div class="ctrl-btn" @click="handleReloadClick">
            <van-icon name="replay" />
            <span>刷新</span>
          </div>
        </template>
      </div>

      <!-- 右下角操作菜单 -->
      <div class="footer-menus" v-if="state === 0">
        <div class="footer-menus_block">
          <span>人声</span>
          <van-switch :value="audioManualSwitch" :disabled="!hasAudio" size=".4rem" @input="handleAudioSwitch" />
        </div>
        <div class="footer-menus_block">
          <span>背景音乐</span>
          <van-switch :value="bgmManualSwitch" :disabled="!hasBgm" size=".4rem" @input="handleBgmSwitch" />
        </div>
      </div>
    </template>

    <!-- 进度面板 -->
    <div class="progress-pannel" v-if="isProgressPannelVisible">
      <div class="row" v-for="(item, $index) of progressList" :key="$index" :class="['__state_' + item.state]">
        <template v-if="0 === item.state">
          <van-icon name="clock-o" />
        </template>
        <!-- 任务执行中 -->
        <template v-else-if="1 === item.state">
          <van-loading type="spinner" color="#1989fa" size="20" />
        </template>
        <!-- 任务成功 -->
        <template v-else-if="2 === item.state">
          <van-icon name="passed" />
        </template>
        <!-- 任务错误 -->
        <template v-else-if="3 === item.state">
          <van-icon name="close" color="#cf1322" />
        </template>
        {{ item.text }}
      </div>
      <div class="row txt" :class="{ err: 3 === progressList[1].state }">
        {{ progressList[1].err ? progressList[1].err : '小竹猫(v' + version + ')' }}
      </div>
      <van-button
        type="info"
        style="display: block; margin: 0 auto; margin-top: 0.3rem; width: 3rem"
        size="small"
        v-if="isAllProgressDone"
        :plain="!isAllProgressComplete"
        @click="handleProgressConfirmButtonClick"
        >开播<span v-if="prepareStartLiveCountDown">({{ Math.floor(prepareStartLiveCountDown) }})</span></van-button
      >
    </div>

    <!-- 下一场倒计时弹窗 -->
    <van-dialog
      v-model="isCountDownPannelVisible"
      title="已下播"
      show-cancel-button
      confirm-button-text="立即开播"
      confirmButtonColor="#1989fa"
      @confirm="handleCountDownDialogConfirm"
      @cancel="handleCountDownDialogClose"
    >
      <div class="count-down-pannel">
        将在<van-count-down :time="countDownTime" ref="countDown" format="mm:ss" />后自动开启下一场直播
      </div>
    </van-dialog>

    <!-- 资源下载进度面板 -->
    <van-dialog
      v-model="isDownloadPannelVisible"
      :show-cancel-button="false"
      :show-confirm-button="false"
      class="download-pannel"
    >
      <p class="download-pannel_title">正在下载资源包</p>
      <div class="download-pannel_txt">
        <p style="float: left">
          正在下载第<span>{{ downloadTask.current + 1 }}</span
          >个
        </p>
        <p style="float: right">
          总数<span>{{ downloadTask.total }}</span
          >个
        </p>
      </div>
      <van-progress :percentage="downloadTask.precent" />
    </van-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { Dialog, Notify } from 'vant'

import XZMService from 'xzm-service-client'

import request from '@/libs/request'
import sleep from '@/utils/sleep'
import { timeFormat } from '@/utils'
import event from '@/event'
import MediaComponent from '@/components/Media'
import TextComponent from '@/components/Text'
import ImageComponent from '@/components/Image'
import ProductsComponent from '@/components/Products'
import CameraComponent from '@/components/Camera'

const IS_DEV = process.env.NODE_ENV === 'development'

const EOS_LOGIN_PAGE = '/livesite/login'
const EOS_LOGIN_URL = 'https://eos.douyin.com' + EOS_LOGIN_PAGE

const Bridge = window.__bridge__ || null

window.onPageFinished = function () {}

window.xlog = function () {}

window.onResume = function () {
  event.$emit('app:resume')
}

window.onPermission = function (data) {
  log('权限回调 =>', data)
  try {
    const tmp = JSON.parse(data)
    Object.keys(tmp).forEach((key) => {
      event.$emit(`permission:${key}`, tmp[key])
    })
  } catch (err) {
    log('权限回调数据解析失败', data, err)
  }
}

export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      COMPONENTS: {
        camera: CameraComponent,
        video: MediaComponent,
        bgm: MediaComponent,
        audio: MediaComponent,
        text: TextComponent,
        image: ImageComponent,
        products: ProductsComponent
      },
      appFeatures: [],
      /**
       * 直播状态
       * 0 未开始
       * 1 直播中
       */
      state: 0,
      version: '1.1.24',
      progressList: [],
      orderText: {
        random: '随机',
        default: '顺序'
      },
      currentPermission: null,
      permissionData: {
        camera: {
          name: 'camera',
          label: '摄像头',
          desc: '拍摄人像或实时画面',
          img: null
        },
        accessibility: {
          name: 'accessibility',
          label: '无障碍',
          desc: '定时自动上下播',
          img: require('./assets/permisison-accessibility.png')
        },
        overlay: {
          name: 'overlay',
          label: '悬浮窗',
          desc: '开播时显示一个启动摄像头的过渡画面',
          img: require('./assets/permisison-overlay.png')
        },
        startInBackground: {
          name: 'startInBackground',
          label: '后台弹出界面',
          desc: '开播成功自动切换会播放界面',
          img: require('./assets/permisison-background.png')
        }
      },
      hasAudio: false,
      hasBgm: false,
      isMenuVisible: true,
      isStopBtnVisible: false,
      isCountDownPannelVisible: false,
      isProgressPannelVisible: false,
      isPermissionPannelVisible: false,
      isDownloadPannelVisible: false,
      eosUserInfo: null,
      // 人声讲解音频手动开关
      audioManualSwitch: false,
      // 背景音乐手动开关
      bgmManualSwitch: false,
      downloadTask: {
        total: 0,
        current: 0,
        precent: 0,
        err: false
      },
      // 下载的资源列表
      downloadList: [],
      // 是否是手动下播
      isManualStop: false,
      // 登录和铺货操作完成，等待开播的倒计时，单位毫秒
      prepareStartLiveCountDown: 0,
      stopBroadcastProgressTask: {
        val: 0,
        text: '0'
      },
      // 货盘商品
      products: [],
      // 商品数量
      productLen: 0,
      firstStart: true,
      // 本场开播时间
      startBroadcastAt: null,
      // 紧急下播信息
      emergencyBraking: null,
      // 媒体解码错误信息
      mediaDecodeError: null,
      // 商品话术列表
      productTextList: [],
      // emergencyBraking: {
      //   reason: 'test-录播',
      //   desc: '你在直播中，存在循环播放音/视频宣传商品行为，严重影响消费者体验，扰乱平台秩序。',
      //   penalize: '扣除信用分12分;直播购物袋违规商品下架',
      //   ts: Date.now(),
      //   video:
      //     'https://v83-016.douyinvod.com/f59b2d349d6ea40af5c227e7f9fbae6b/63c68726/dash/hls-v03af6g10000cdn3vdjc77ucjvj4j98g/tos-cn-v-f4fca2/9c4ec6146fbd46b1859fb77f22ee20d8/main.m3u8?a=1128&ch=0&cr=3&dr=0&cd=0%7C0%7C0%7C3&br=538&bt=538&cs=0&ds=2&mime_type=video_mp4&qs=0&rc=Njg0OGlnNjNoZzs5OTRpZ0Bpajw3eWY6Zmc2ZzMzNDlkM0A1Xl8zMl9jXl8xLzEtNmAtYSM1Z2dncjRnc2tgLS1kYzBzcw%3D%3D&l=2023011019304847BC1428F58C35138C1D&btag=a8000'
      // },
      countDownTime: 0,
      deviceid: null,
      setting: {},
      data: {},
      id: this.$route.params.id
    }
  },
  watch: {
    isMenuVisible() {
      if (this.autoHideMenuTimmer) {
        clearTimeout(this.autoHideMenuTimmer)
      }
      if (this.isMenuVisible && 1 === this.state) {
        this.autoHideMenuTimmer = setTimeout(() => {
          this.isMenuVisible = false
        }, 10 * 1000)
      }
    }
  },
  computed: {
    isAllProgressDone() {
      const len = this.progressList.length
      return this.progressList.filter((item) => [2, 3].indexOf(item.state) !== -1).length === len
    },
    isAllProgressComplete() {
      const len = this.progressList.length
      return this.progressList.filter((item) => item.state === 2).length === len
    },
    assets() {
      if (this.data.item) {
        return this.data.item.filter((item) => item.enable)
      }
      return []
    },
    currentResourceNo() {
      return this.downloadList.filter((item) => ['downloading', 'success'].indexOf(item.status) !== -1).length
    },
    allDownloadComplete() {
      return (this.downloadList.filter((item) => 'success' === item.status).length = this.downloadList.length)
    }
  },
  async created() {
    log('web版本', this.version, location.pathname)
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        log(`请求失败 ${error.request.responseURL} ${error.request.status} ${error.request.responseText}`)
        if (401 === error.response.status && 0 === this.state) {
          request.setToken(null)
          localStorage.clear()
          Notify({ message: '登录状态失效', type: 'danger' })
          this.$router.push({
            name: 'Login'
          })
        }
        return Promise.reject(error)
      }
    )

    this.currentPermission = this.permissionData['camera']
    // this.isPermissionPannelVisible = true
    this.resetProgressPannel()

    // const pad = (str) => ('0' + str).slice(-2)
    // const getTimeStrings = (d = new Date()) => {
    //   return [
    //     d.getFullYear(),
    //     pad(d.getMonth() + 1),
    //     pad(d.getDate()),
    //     pad(d.getHours()),
    //     pad(d.getMinutes()),
    //     pad(d.getSeconds())
    //   ]
    // }
    // const startAt = getTimeStrings().join('')
    // this._startAt = startAt

    await this.fetch()

    // 创建遥控器
    // this.createRemoteTunnel()

    if (this.data.cache) {
      const downloadResult = await this.loadResource()
      if (downloadResult) {
        log('资源缓存完毕')
      }
    }

    // 不管用不用缓存，都应该走start
    event.$emit('start')

    event.$on('command:stop', () => {
      log('[command:stop] 收到远程下播指令')
      switch (this.state) {
        case 0:
          log('[command:stop] 未开播 不执行操作')
          break
        case 1:
          log('[command:stop] 开播中 执行下播操作')
          this.stopBroadCast()
          break
      }
    })
  },
  async mounted() {},
  methods: {
    playWxVoice(voiceId) {
      log(`[playWxVoice:${voiceId}] start `)
      this.$http
        .get(`/api/voices/${voiceId}`, {
          responseType: 'blob',
          responseEncoding: ''
        })
        .then((res) => {
          const audio = this.wxAudio || new Audio()
          this.wxAudio = audio
          audio.oncanplay = () => {
            event.$emit('voicePlayStart')
            audio.play()
          }
          audio.onended = function () {
            URL.revokeObjectURL(audio.src)
            audio.src = ''
            event.$emit('voicePlayEnd')
          }
          audio.src = URL.createObjectURL(res.data)
        })
        .catch((err) => {
          log(`[playWxVoice:${voiceId}] err >> ${err.message}`)
        })
    },
    showMediaDecodeError() {
      if (!this.mediaDecodeError) {
        return
      }
      Dialog.alert({
        className: 'violation-dialog',
        message: `
        <div class="violation-dialog_content">
          <p class="violation-dialog_content_title">媒体解码错误下播</p>
          <p>类型:<span>${this.mediaDecodeError.type}<span></p>
          <p>标签:<span>${this.mediaDecodeError.label}<span></p>
          <p>ID:<span>${this.mediaDecodeError.id}</span></p>
          <p>时间:<span>${timeFormat(Date.now())}</span></p>
        </div>`,
        allowHtml: true
      })
      this.mediaDecodeError = null
    },
    handleMediaDecodeError(media) {
      this.mediaDecodeError = media
      if (0 === this.state) {
        this.showMediaDecodeError()
      } else {
        this.stopBroadCast()
      }
    },
    handleMediaTimeUpdate(data) {
      if (!this.remoteControlClient || !this.remoteControlClient.isConnected()) {
        return
      }
      log('[RemoteControl] 上报媒体信息', data)
      this.remoteControlClient.send('media', data)
    },
    handleAudioSwitch(val) {
      localStorage.setItem('xiaozhumao_audio_enable', val)
      location.reload()
    },
    handleBgmSwitch(val) {
      localStorage.setItem('xiaozhumao_bgm_enable', val)
      location.reload()
    },
    createRemoteTunnel() {
      log('[RemoteControl] 创建遥控器')

      if (!this.data || !this.data.id) {
        log('[RemoteControl] 终止创建遥控器: 没有计划数据')
        return
      }

      const pid = this.data.id
      const token = localStorage.getItem('xiaozhumao_token')

      if (!token || !pid) {
        log('[RemoteControl] 终止创建遥控器: 缺少token或者计划id')
        return
      }

      const client = new XZMService('app', pid, token, IS_DEV)
      this.remoteControlClient = client

      client.on('connect', () => {
        log(`[RemoteControl] 连接成功: id: ${pid} token: ${token}`)
      })

      client.on('reconnect', () => {
        log('[RemoteControl] 重新连接')
      })

      client.on('connect_error', () => {
        log('[RemoteControl] 连接失败')
      })

      client.on('disconnect', () => {
        log('[RemoteControl] 连接已断开')
      })

      // 一键开播
      client.on('start', () => {
        log('[RemoteControl] 收到消息: start')
        if (0 === this.state) {
          // 如果存在违规弹窗，先关掉
          Dialog.close()
          this.startNextBroadcast()
        }
        return { value: true, message: Date.now() }
      })

      // 一键下播
      client.on('stop', () => {
        log('[RemoteControl] 收到消息: stop')
        if (1 === this.state) {
          this.stopBroadCast()
        }
        return { value: true, message: Date.now() }
      })

      client.on('mediaUpdate', (data) => {
        log('[RemoteControl] 收到消息: mediaUpdate', data)
        event.$emit('mediaUpdate', data)
      })

      client.on('wxVoice', (data) => {
        log('[RemoteControl] 收到消息: wxVoice', data)
        if (!data || !data.value) {
          return
        }
        this.playWxVoice(data.value)
      })

      client.on('guide', (data) => {
        log('[RemoteControl] 收到消息: guide', data)
        if (
          !data ||
          !data.hasOwnProperty('enable') ||
          'boolean' !== typeof data.enable ||
          !data.hasOwnProperty('value') ||
          !data.value.hasOwnProperty('interval') ||
          !data.value.hasOwnProperty('text') ||
          !Array.isArray(data.value.text)
        ) {
          return
        }
        this.startAutoGuide({
          enable: data.enable,
          params: data.value
        })
      })

      client.on('autoReply', (data) => {
        log(`[RemoteControl] 收到消息: autoReply`, data)
        // 校验自动回复的配置项
        if (
          !data ||
          !data.hasOwnProperty('enable') ||
          !data.hasOwnProperty('value') ||
          typeof data.enable !== 'boolean' ||
          !Array.isArray(data.value)
        ) {
          log(`[RemoteControl] autoReply配置校验失败`)
          return
        }

        this.flushCommentProcessorConfig({
          autoReply: {
            enable: data.enable,
            params: data.value
          }
        })
      })

      client.on('productKeywordsUpdate', (data) => {
        log(`[RemoteControl] 收到消息: productKeywordsUpdate`, data, typeof data)
        if (!data) {
          return
        }
        this.updateProductKeywords(data.id, data.keywords)
      })

      clearInterval(this.reportLiveStateTimmer)
      this.reportLiveStateTimmer = setInterval(() => {
        if (!client || !client.isConnected()) {
          return
        }

        const ret = {
          value: false,
          duration: null
        }

        if (0 === this.state) {
          ret.value = false
        } else {
          ret.value = true
          ret.duration = Date.now() - this.startBroadcastAt
        }

        log('[RemoteControl] 上报直播状态', ret)

        client
          .send('liveState', ret)
          .then(() => {})
          .catch((err) => {
            log('[RemoteControl] 发送失败: liveState', err.message)
          })
      }, 1000)
    },
    async handleReloadClick() {
      if (0 !== this.state) {
        return
      }
      location.reload()
    },
    async handleHomeBtnClick() {
      localStorage.removeItem('xiaozhumao_live_id')
      localStorage.removeItem('xiaozhumao_device_id')
      await this.$nextTick()
      location.href = '/login'
    },
    loadResource() {
      if (-1 === navigator.userAgent.toLowerCase().indexOf('xiaozhumao')) {
        return true
      }

      if (!Bridge.download) {
        return true
      }

      log('开始预载媒体资源')

      this.isDownloadPannelVisible = true

      return new Promise((resolve) => {
        // 下载资源的回调
        window.onResDownload = async (res) => {
          /**
           * res的格式为 {number},{number},{number}
           * number === -1    : 下载失败
           * number === 100   : 下载完成
           * 0 < number < 100 : 下载中
           */
          log('download callback', res)
          const arr = res.split(',')

          let succeeded = 0
          let failed = 0
          arr.forEach((precent, index) => {
            const p = parseInt(precent)

            if (p === 100) {
              succeeded++
            }

            if (p === -1) {
              failed++
            }

            if (0 !== p) {
              this.downloadTask.current = index
              this.downloadTask.precent = Math.max(p, 0)
            }
          })

          if (succeeded === this.downloadList.length) {
            await sleep(1000)
            this.isDownloadPannelVisible = false
            resolve(true)
            return
          }

          if (succeeded + failed === this.downloadList.length) {
            this.downloadTask.err = true
            resolve(false)
          }
        }

        if (!this.data || !this.data.item) {
          return
        }

        this.downloadList = this.data.item
          .filter((item) => item.enable && ['video', 'bgm', 'audio'].indexOf(item.type) !== -1)
          .reduce((ret, cur) => {
            ret.push(
              ...cur.source.map((item) => {
                return item.url
              })
            )
            return ret
          }, [])

        log('媒体列表 >>', JSON.stringify(this.downloadList))
        this.downloadTask = {
          total: this.downloadList.length,
          current: 0,
          precent: 0,
          err: false
        }

        Bridge.download(JSON.stringify(this.downloadList))
      })
    },
    async handleComponentIndexUpdate(data, index) {
      log(`存储媒体${data.type}索引`, index, data)
      data.index = index
      try {
        await this.$http.put(`/api/devices/${this.id}/strapi-live-config-index`, {
          item_id: data.id,
          index
        })
      } catch (err) {
        log('更新媒体索引失败', err.message)
      }
    },
    async handlePermissionPannelConfirm() {
      Bridge.requirePermission([this.currentPermission.name])

      if (this.currentPermission.name === 'camera') {
        const permissionState = await new Promise((resolve) => {
          event.$once('permission:camera', (state) => {
            resolve(state)
          })
        })
        if (!permissionState) {
          Bridge.requirePermission(['permissionSettings'])
        }
      }

      this.isPermissionPannelVisible = false
    },
    handleCountDownDialogConfirm() {
      event.$emit('countDownDialog:confirm')
    },
    handleCountDownDialogClose() {
      event.$emit('countDownDialog:cancel')
    },
    resetProgressPannel() {
      this.progressList = [
        {
          /**
           * 0 未开始
           * 1 进行中
           * 2 成功
           * 3 失败
           */
          state: 0,
          text: '登录百应',
          err: null
        },
        {
          state: 0,
          text: '自动铺货',
          err: null
        }
      ]
    },
    handleProgressConfirmButtonClick() {
      if (this.isAllProgressDone && !this.isAllProgressComplete) {
        this.isProgressPannelVisible = false
        this.isMenuVisible = true
        return
      }
      cancelAnimationFrame(this.prepareCountDownTimmer)
      this.prepareStartLiveCountDown = 0
      this.startBroadcast()
    },
    startPrepareCountdown() {
      if (this.prepareCountDownTimmer) {
        cancelAnimationFrame(this.prepareCountDownTimmer)
      }
      const countDown = 10 * 1000
      const startAt = Date.now()
      const loop = () => {
        const tmp = countDown - (Date.now() - startAt)
        if (tmp <= 0) {
          this.prepareStartLiveCountDown = 0
          cancelAnimationFrame(this.prepareCountDownTimmer)
          this.startBroadcast()
        } else {
          this.prepareStartLiveCountDown = Math.floor(tmp / 1000)
          this.prepareCountDownTimmer = requestAnimationFrame(loop)
        }
      }
      loop()
    },
    /**
     *
     * @param { Number } id 货盘商品id（nova数据库中的id)
     * @param { Array<String> } keywords 关键词
     */
    updateProductKeywords(id, keywords = []) {
      log('[updateProductKeywords] start', id, keywords)
      if (!id) {
        return
      }
      let productId
      for (const item of this.products) {
        if (item.id === id) {
          item.keywords = keywords
          productId = item.product_id
          break
        }
      }
      if (!productId) {
        log('[updateProductKeywords] break, not found product')
        return
      }
      log('[updateProductKeywords] local product keywords updated')
      this.exec(
        'buyin',
        (productId, keywords) => {
          window.__updateProductKeywords && window.__updateProductKeywords(productId, keywords)
        },
        productId,
        keywords
      )
    },
    // 设置货盘商品
    setProductList(list) {
      log(
        '[setProductList] 设置货盘商品',
        list.map((item) => {
          return {
            id: item.id,
            product_id: item.product_id,
            keywords: item.keywords
          }
        })
      )
      if (!list || !Array.isArray(list)) {
        return
      }
      this.exec(
        'buyin',
        (list) => {
          window.__productList = list.map((item) => {
            return Object.assign({}, item, {
              cover: undefined
            })
          })
          // 将或盘中的商品keywords字段合并到已上架的商品中
          if (window.__products && window.__products.length) {
            console.log('[buyin:setProductList] 开始合并商品列表')
            for (var i = 0, len = window.__products.length; i < len; i++) {
              var cur = window.__products[i]
              var _tmp = window.__productList.filter(function (item) {
                return item.product_id === cur.product_id
              })
              if (_tmp.length) {
                cur.keywords = _tmp[0].keywords
              }
            }
            console.log('[buyin:setProductList] 合并商品列表完成')
          }
        },
        list
      )
    },
    // 设置货盘商品回复话术
    setProductTextList(list = []) {
      log('设置货盘商品关键词', list)
      if (!list || !Array.isArray(list)) {
        return
      }
      this.exec(
        'buyin',
        (list) => {
          window.__productTextList = list
        },
        list
      )
    },
    setProductKeywordsProcessor() {
      log('[setProductKeywordsProcessor] 挂载货盘商品关键词相关函数')

      this.exec('buyin', () => {
        if (!window.__updateProductKeywords) {
          window.__updateProductKeywords = function (productId, keywords) {
            console.log('[buyin:updateProductKeywords] start', productId, JSON.stringify(keywords))
            if (!productId || !keywords || !window.__products) {
              return
            }
            for (var i = 0, len = window.__products.length; i < len; i++) {
              var cur = window.__products[i]
              console.log('[buyin:updateProductKeywords] prematch', JSON.stringify(cur))
              if (cur.product_id === productId) {
                cur.keywords = keywords
                console.log('[buyin:updateProductKeywords] success')
                break
              }
              if (i === len - 1) {
                console.log('[buyin:updateProductKeywords] mismatch')
              }
            }
            console.log(
              '[buyin:updateProductKeywords] done',
              JSON.stringify(
                window.__products.map((item) => {
                  return {
                    product_id: item.product_id,
                    keywords: item.keywords
                  }
                })
              )
            )
          }
        }

        if (!window.__matchProductKeywords) {
          /**
           *
           * @param { String } message 文本弹幕消息
           * @param { String } nickname 用户昵称
           * @param { Array<Product> } products 商品列表
           * @param { Array<String> } list 话术列表
           * @param { Number } max 返回话术的最大字符长度
           * @return { String } 回复的话术
           */
          window.__matchProductKeywords = function (message, nickname, products, list, max = 50) {
            console.log(
              '[matchProductKeywords] start',
              message,
              nickname,
              'product len >>',
              products.length,
              'keyword len >>',
              list.length,
              'max >>',
              max
            )
            if (!message || !nickname || !products || !products.length || !list || !list.length) {
              console.log('[matchProductKeywords] break, params invaild')
              return
            }

            var text = message.trim()

            if (!text) {
              console.log('[matchProductKeywords] break, message empty')
              return
            }

            var tpl = list[Math.floor(Math.random() * list.length)]

            if (!tpl) {
              console.log('[matchProductKeywords] break, tpl empty')
              return
            }

            console.log('[matchProductKeywords] tpl >>', tpl)

            // 命中的商品小黄车序号
            var nos = []

            for (var i = 0, len = products.length; i < len; i++) {
              var product = products[i]
              var keywords = product.keywords || []
              // 商品没有配置关键词,跳过
              if (!keywords || !keywords.length) {
                continue
              }
              for (var j = 0, kLen = keywords.length; j < kLen; j++) {
                var keyword = keywords[j]
                if (text.indexOf(keyword) !== -1) {
                  nos.push(i + 1)
                  console.log('[matchProductKeywords] match >>', keyword, i + 1)
                  break
                }
              }
            }

            if (!nos.length) {
              return
            }

            var size = (max - (tpl.length - 7)) / 3
            size = Math.max(1, Math.min(5, size))

            var noStr = nos.slice(0, size).join(',') + '号'

            console.log('[matchProductKeywords] nos >>', JSON.stringify(nos), noStr)

            var msg = tpl.replace(/\{\{([^\}]+)\}\}/gim, (placeholder, flag) => {
              // 小黄车商品序号
              if ('nos' === flag) {
                return noStr
              }
              return ''
            })

            if (msg.length > max) {
              return msg.slice(0, max)
            }

            var prefix = '@' + nickname + ' '
            if (prefix.length + msg.length > max) {
              return msg
            }

            return prefix + msg
          }
        }
      })
    },
    flushCommentProcessorConfig(data = {}) {
      log('设置弹幕相关配置')
      const autoReplyConfig = data.autoReply || this.getAutoReplyConfig()
      const autoForbidConfig = data.autoForbid || this.getAutoForbidConfig()

      log('自动回复配置 >>', autoReplyConfig)
      log('自动禁言配置 >>', autoForbidConfig)

      this.exec(
        'buyin',
        (autoReplyConfig, autoForbidConfig) => {
          // 初始化自动回复默认配置
          if (!window.__autoReplyConfig) {
            window.__autoReplyConfig = {
              enable: false,
              params: []
            }
          }

          // 初始化自动禁言默认配置
          if (!window.__autoForbidConfig) {
            window.__autoForbidConfig = {
              enable: false,
              params: []
            }
          }

          // 设置自动回复配置数据
          window.__setAutoReplyConfig =
            window.__setAutoReplyConfig ||
            function (config) {
              console.log('[buyin:CommentProcessor] setAutoReplyConfig', JSON.stringify(config))
              if (!config) {
                return
              }
              const ret = {
                enable: false,
                params: []
              }
              ret.enable = config.enable
              if (config.params && config.params.length) {
                ret.params = config.params
              }
              window.__autoReplyConfig = ret
            }

          // 设置自动禁言配置
          window.__setAutoForbidConfig =
            window.__setAutoForbidConfig ||
            function (config) {
              console.log('[buyin:CommentProcessor] setAutoForbidConfig', JSON.stringify(config))
              if (!config) {
                return
              }
              const ret = {
                enable: false,
                params: []
              }
              ret.enable = config.enable
              if (config.params && Array.isArray(config.params) && config.params.length) {
                ret.params = config.params
              }
              window.__autoForbidConfig = ret
            }

          window.__setAutoReplyConfig(autoReplyConfig)
          window.__setAutoForbidConfig(autoForbidConfig)
        },
        autoReplyConfig,
        autoForbidConfig
      )
    },
    async handleLiveBtnClick() {
      // 未开播
      if (0 === this.state) {
        this.isMenuVisible = false
        this.resetProgressPannel()

        const loginResult = await this.startBuyinLogin()
        return
        this.hideView('buyin')

        if (!loginResult.success) {
          switch (loginResult.code) {
            case BUYIN_LOGIN_CODE.NO_BIND_DOUYIN:
              Dialog.alert({
                message: '未绑定抖音号,请联系客服'
              })
              break
            case BUYIN_LOGIN_CODE.NO_BIND_BUYIN:
              Dialog.alert({
                message: '未绑定百应,请联系客服'
              })
              break
            case BUYIN_LOGIN_CODE.GET_BUYINID_FAILED:
              Dialog.confirm({
                message: '用户未开通百应后台',
                confirmButtonText: '重新登录',
                confirmButtonColor: '#1989fa'
              })
                .then(async () => {
                  this.exec('buyin', () => window.fetch('/index/logout'))
                  await sleep(1000)
                  this.handleLiveBtnClick()
                })
                .catch(() => {})
              break
            case BUYIN_LOGIN_CODE.BUYINID_NOT_MATCH:
              Dialog.confirm({
                message: '百应账号与直播间不匹配,终止铺货;如新号开播,请新建直播间',
                confirmButtonText: '重新登录',
                confirmButtonColor: '#1989fa'
              })
                .then(async () => {
                  this.exec('buyin', () => window.fetch('/index/logout'))
                  await sleep(1000)
                  this.handleLiveBtnClick()
                })
                .catch(() => {})
              break
            case BUYIN_LOGIN_CODE.BIND_PRODUCT_FAILED:
              Dialog.confirm({
                message: '铺货失败',
                confirmButtonText: '重试',
                confirmButtonColor: '#1989fa'
              })
                .then(async () => {
                  this.handleLiveBtnClick()
                })
                .catch(() => {})
              break
          }
          this.isMenuVisible = true
          this.isProgressPannelVisible = false
          return
        }

        await this.$nextTick()

        if (this.isAllProgressComplete) {
          this.startPrepareCountdown()
        }

        return
      }

      // 已开播
      if (1 === this.state) {
        this.isMenuVisible = false
        Dialog.confirm({
          message: '确定要下播吗?'
        })
          .then(() => {
            this.stopBroadCast()
          })
          .catch(() => {})
      }
    },
    getItemConfig(typeName, defaultConfig) {
      const config = Object.assign({}, defaultConfig)
      log('[getItemConfig]', typeName, defaultConfig)

      if (!this.data || !this.data.item.length) {
        log('[getItemConfig] return default 1')
        return config
      }

      const [tmp] = this.data.item.filter((item) => item.type === typeName)

      if (!tmp || 'boolean' !== typeof tmp.enable || !tmp.params) {
        log('[getItemConfig] return default 2')
        return config
      }

      config.enable = tmp.enable
      config.params = tmp.params
      log('[getItemConfig] return new config')

      return config
    },
    getAutoGuideConfig() {
      return this.getItemConfig('guide', {
        enable: false,
        params: null
      })
    },
    getAutoForbidConfig() {
      return this.getItemConfig('auto_forbid', {
        enable: false,
        params: []
      })
    },
    getAutoReplyConfig() {
      return this.getItemConfig('auto_reply', {
        enable: false,
        params: []
      })
    },
    getProductTextList() {
      return this.productTextList || []
    },
    async handleTouchStart() {
      const now = performance.now()
      if (!this.previousTouch || now - this.previousTouch > 500) {
        this.previousTouch = now
        return
      }
      this.isMenuVisible = !this.isMenuVisible
    },
    handleOpenBuyin() {
      this.openBuyin()
    },
    async checkPermission() {
      const permission = JSON.parse(Bridge.checkPermissions() || {})
      log('check permission response', permission)

      if (!permission.camera) {
        this.currentPermission = this.permissionData['camera']
        log('current permission', this.currentPermission)
        this.isPermissionPannelVisible = true
        return
      }

      if (!permission.accessibility) {
        this.currentPermission = this.permissionData['accessibility']
        log('current permission', this.currentPermission)
        this.isPermissionPannelVisible = true
        return
      }

      if (this.appFeatures.indexOf('overlay') !== -1 && !permission.overlay) {
        this.currentPermission = this.permissionData['overlay']
        log('current permission', this.currentPermission)
        this.isPermissionPannelVisible = true
        return
      }

      if (!permission.startInBackground) {
        this.currentPermission = this.permissionData['startInBackground']
        log('current permission', this.currentPermission)
        this.isPermissionPannelVisible = true
        return
      }

      return true
    },
    async startBroadcast() {
      if (Bridge) {
        if (Bridge.getSupportFeatures) {
          try {
            const tmp = Bridge.getSupportFeatures()
            this.appFeatures = JSON.parse(tmp)
          } catch (err) {}
        }

        const isAllPermissionPassed = await this.checkPermission()
        if (!isAllPermissionPassed) {
          log('权限不足无法开播')
          return
        }
        log('开始打开抖音自动开播', this.data.douyinid)

        // 检查app是否支持开播遮罩功能
        if (this.appFeatures.indexOf('overlay') !== -1) {
          log('启用遮罩')
          Bridge.beginBroadcast(this.data.douyinid, true)
        } else {
          log('不启用遮罩')
          Bridge.beginBroadcast(this.data.douyinid)
        }
      }

      try {
        window.onStartBroadcast = (_data) => {
          log('开播回调', _data)

          let data = {
            success: false
          }

          try {
            data = JSON.parse(_data)
          } catch (err) {
            log('开播回调参数解析失败')
            return
          }

          this.isMenuVisible = false

          if (data && !data.success) {
            let msg = ''
            switch (data.code) {
              case 'dyNumberNotMatch':
                msg = '抖音号不匹配'
                break
              case 'noProduct':
                msg = '小黄车中没有商品'
                break
            }
            Dialog.alert({
              message: `开播失败: ${msg}`
            })
            return
          }

          log('20后检查小黄车商品数量')
          setTimeout(async () => {
            const productLen = await this.getProductsLenAgain()
            if (productLen <= 0) {
              log('开播完成 但是小黄车没有商品 准备下播')
              this.stopBroadCast()
            }
          }, 20 * 1000)

          this.exec('buyin', () => {
            // 获取room_id
            window
              .fetch('/data/life/live/status/')
              .then((res) => res.json())
              .then((data) => {
                if (data && data.code === 0 && data.data && data.data.room_id) {
                  window.__room_id__ = data.data.room_id
                }
              })
          })

          // 记录本场开播时间
          this.startBroadcastAt = Date.now()

          if (this.firstStart) {
            event.$emit('reset')
            this.firstStart = false
          } else {
            event.$emit('start')
          }

          // 启动直播状态监控
          // this.startLivingMonitor()

          // 启动定时引导
          this.startAutoGuide()

          // 启动弹幕处理
          this.flushCommentProcessorConfig()
          this.startCommentProcessor()

          // 启动违规监控
          // this.startViolationMonitor()

          this.pushBroadcastEvent('start')

          log(`本段讲解音频播放完毕后,将自动关播`)
          this.isProgressPannelVisible = false
          this.resetProgressPannel()
          this.state = 1

          // setTimeout(() => {
          //   log('直播时长结束,开始调用自动停播')
          //   Bridge.stopBroadCast()
          // }, 30 * 1000)

          event.$once('audio:ended', (no) => {
            if (0 === this.state) {
              return
            }
            log('本段讲解音频播放完成,开始调用自动下播')
            Bridge.stopBroadCast()
          })

          // clearTimeout(this.stopBroadCastTimmer)
          // this.stopBroadCastTimmer = setTimeout(() => {
          //   log('直播时长结束,开始调用自动停播')
          //   Bridge.stopBroadCast()
          // }, this.data.duration * 60 * 1000)
        }

        window.onStopBroadcast = async (_data) => {
          log('下播回调', _data)

          let data = {
            success: false
          }

          try {
            data = JSON.parse(_data)
          } catch (err) {
            log('下播回调参数解析失败')
            return
          }

          this.state = 0

          // 重置商品数量
          this.productLen = 0

          this.isMenuVisible = true

          event.$emit('stop')

          this.pushBroadcastEvent('stop')

          if (this.isManualStop) {
            log('手动下播,不自动开播')
            this.isManualStop = false
            if (this.emergencyBraking) {
              Dialog.alert({
                className: 'violation-dialog',
                message: `
              <div class="violation-dialog_content">
                <p class="violation-dialog_content_title">出现违规紧急下播</p>
                <p>类型:<span>${this.emergencyBraking.reason}<span></p>
                <p>描述:<span>${this.emergencyBraking.desc}</span></p>
                <p>处罚:<span>${this.emergencyBraking.penalize || '无'}</span></p>
                <p>时间:<span>${timeFormat(this.emergencyBraking.ts)}</span></p>
              </div>`,
                allowHtml: true
              })
              this.emergencyBraking = null
            } else if (this.mediaDecodeError) {
              this.showMediaDecodeError()
            }
            return
          }

          const stopBroadcastCountdown = (this.data.interval || 1) + Math.random() * 5
          // const stopBroadcastCountdown = 0.4
          log(`${stopBroadcastCountdown}分钟后重新开播`)

          this.isCountDownPannelVisible = true
          this.countDownTime = stopBroadcastCountdown * 60 * 1000
          await this.$nextTick()

          this.$refs.countDown.reset()
          this.$refs.countDown.start()

          let timmer = 0

          event.$once('countDownDialog:cancel', () => {
            clearTimeout(timmer)
          })

          event.$once('countDownDialog:confirm', () => {
            clearTimeout(timmer)
            this.startNextBroadcast()
          })

          timmer = setTimeout(() => {
            this.isCountDownPannelVisible = false
            this.startNextBroadcast()
          }, stopBroadcastCountdown * 60 * 1000)
        }
      } catch (err) {
        log('自动开播失败')
      }
    },
    async startNextBroadcast() {
      await this.fetch()

      if (this.data.cache) {
        const downloadResult = await this.loadResource()
        if (!downloadResult) {
          return
        }
      }

      const result = await this.startBuyinLogin()
      if (!result.success) {
        log('下一场开播失败,3秒后重试,错误码', result.code)
        await sleep(3000)
        this.startNextBroadcast()
        return
      }
      await this.$nextTick()
      if (this.isAllProgressComplete) {
        this.startBroadcast()
      }
    },
    stopBroadCast() {
      log('手动下播')
      // clearTimeout(this.stopBroadCastTimmer)

      this.isManualStop = true

      if (Bridge) {
        try {
          Bridge.stopBroadCast()
        } catch (err) {}
      }
    },

    /**
     * 一键铺货
     */
    async pushProducts(plan) {
      console.log('[pushProducts] 开始')
      return new Promise((resolve) => {
        window.__push_products_callback__ = (result) => {
          console.log('[pushProducts] 铺货结果:', JSON.stringify(result))
          resolve(result)
        }
        window.__push_products_notify__ = (msg) => {
          console.log('[pushProducts] msg:', msg)
          this.progressList[1].text = msg
        }
        this.exec(
          'buyin',
          (plan) => {
            const resolve = (success, msg) => {
              const result = { success, msg }
              window.__bridge__.injectJs(
                'render',
                `javascript:window.__push_products_callback__(${JSON.stringify(result)})`
              )
            }
            const notify = (msg) => {
              window.__bridge__.injectJs('render', `javascript:window.__push_products_notify__(${JSON.stringify(msg)})`)
            }
            // 第一步，获取直播商品计划
            const __fetchPlanList = () => {
              return new Promise((resolve, reject) => {
                notify('获取直播计划列表')
                window
                  .fetch('/data/life/live/plan/list/?page=0&limit=40')
                  .then((res) => res.json())
                  .then((data) => {
                    if (data && data.data && data.data.plan_list) {
                      let infos = data.data.plan_list
                      notify('获取到' + infos.length + '个直播计划')
                      if (infos.length > 1) {
                        infos = infos.filter((info) => info.title === plan)
                      }
                      if (infos.length === 1) {
                        resolve(infos[0])
                      } else {
                        reject('无法找到有效的直播计划')
                      }
                    } else {
                      reject('获取直播计划列表失败:' + ((data && data.msg) || JSON.stringify(data)))
                    }
                  })
                  .catch((err) => reject('获取直播计划列表失败:' + err.message))
              })
            }
            // 根据计划ID获取商品列表
            const __fetchProductsByPlanId = (planId) => {
              notify('根据计划ID获取商品列表')
              return new Promise((resolve, reject) => {
                window
                  .fetch('/data/life/live/plan/detail/?plan_id=' + planId)
                  .then((res) => res.json())
                  .then((data) => {
                    if (data && data.data && data.data.info) {
                      notify('获取到' + data.data.info.length + '个商品')
                      resolve({
                        products: data.data.info,
                        planId: planId
                      })
                    } else {
                      reject('获取商品计划列表失败:' + ((data && data.msg) || JSON.stringify(data)))
                    }
                  })
                  .catch((err) => reject('获取商品计划列表失败:' + err.message))
              })
            }
            // 铺货
            const __bindProducts = (products, planId) => {
              return new Promise((resolve, reject) => {
                notify('开始铺货' + products.length + '个商品')
                window
                  .fetch('/data/life/live/agg/card/save/', {
                    method: 'POST',
                    headers: { 'content-type': 'application/json' },
                    body: JSON.stringify({
                      agg_card_id: '0',
                      agg_card_type: 1,
                      card_data: JSON.stringify(
                        products.map((item) => {
                          return {
                            card_id: item.card_id,
                            auth_type: item.source,
                            live_card_type: item.live_card_type,
                            plan_id: planId
                          }
                        })
                      ),
                      room_id: '0',
                      source_type: 1
                    })
                  })
                  .then((res) => res.json())
                  .then((data) => {
                    console.log('[pushProducts] 请求完成', JSON.stringify(data))
                    if (data && 0 === data.status_code) {
                      notify('铺货完成')
                      resolve(products.length)
                    } else if (data && 0 !== data.status_code) {
                      reject('铺货失败:' + data.status_msg)
                    } else {
                      reject('铺货失败:' + ((data && data.msg) || JSON.stringify(data)))
                    }
                  })
                  .catch((err) => reject('铺货失败:' + err.message))
              })
            }

            if ('string' === typeof plan) {
              console.log('[pushProducts] 铺货类型', '计划', plan)
              __fetchPlanList()
                .then((plan) => __fetchProductsByPlanId(plan.id))
                .then((data) => __bindProducts(data.products, data.planId))
                .then((result) => resolve(true, result))
                .catch((err) => resolve(false, err.message || err))
            } else {
              resolve(false, '无效的铺货参数')
            }
          },
          plan
        )
      })
    },

    /**
     * 检查登录
     */
    checkEosLogin() {
      log('检查百应 开始')
      this.progressList[0].text = '检查登录状态'
      this.progressList[0].state = 1
      return new Promise((resolve) => {
        window.__check_login_callback__ = (userInfo) => {
          console.log('[checkEosLogin] callback', userInfo)
          resolve(userInfo)
        }

        window.onPageFinished = (viewId, url) => {
          if (viewId !== 'buyin') {
            return
          }

          console.log('[checkEosLogin] onPageFinished', viewId, url)
          // 重置页面css
          if (/\/livesite\/login/.test(url)) {
            this.progressList[0].text = '登录跳转中...'
            console.log('[checkEosLogin] inject css')
            this.exec('buyin', () => {
              var style = document.createElement('style')
              var css =
                'html,body {width: 100%!important;height: 100%!important;overflow: hidden!important;}' +
                '.web-login-scan-code__content__qrcode-wrapper {position: fixed !important;top: 0!important;left: 0!important;width: 100%!important;height: 100%!important;background-color: #fff!important;}' +
                '.web-login-scan-code__content__qrcode-wrapper__qrcode{object-fit:contain!important}'
              style.textContent = css
              document.head.append(style)
            })
          }

          // 检测登录状态
          if (/\/livesite\/live\/current/.test(url)) {
            // if (url.indexOf('log_out=') > -1) {
            //   this.progressList[0].text = '请登录百应'
            // }
            this.exec('buyin', () => {
              const __checkLogin = (a = 1, b = 1) => {
                window
                  .fetch('/data/life/live/user/info/v1/')
                  .then((res) => res.json())
                  .then((json) => {
                    if (json) {
                      console.log('[checkEosLogin] Eos用户接口响应', JSON.stringify(json))
                      if (json && json.douyin_unique_id && json.account_id) {
                        console.log(
                          '[checkEosLogin] 登录成功, douyin_unique_id: ' + json.douyin_unique_id,
                          'account_id: ' + json.account_id
                        )
                        window.__eos_user_info__ = json
                        // // 每条弹幕消息中有个uid，可以用这个字段来甄别弹幕是不是主播自己发的
                        // window.__origin_uid__ = json.data.origin_uid
                        window.__bridge__.injectJs(
                          'render',
                          `javascript:__check_login_callback__(${JSON.stringify(json)})`
                        )
                      }
                      //  else if (location.search.indexOf('log_out=') === -1) {
                      //   console.log('[checkEosLogin] 未登录: ' + JSON.stringify(json.data))
                      //   location.href = '?log_out=1&type=24'
                      // }
                    } else {
                      console.log('[checkEosLogin] 请求登录接口异常: ' + JSON.stringify(json))
                      clearTimeout(window.__checkLoginTimer)
                      window.__checkLoginTimer = setTimeout(() => __checkLogin(b, a + b), a * 1000)
                    }
                  })
                  .catch((e) => {
                    console.log('[checkEosLogin] 请求登录接口失败: ' + e.message)
                    clearTimeout(window.__checkLoginTimer)
                    window.__checkLoginTimer = setTimeout(() => __checkLogin(b, a + b), a * 1000)
                  })
              }
              __checkLogin()
            })
            return
          }

          // 登录成功
          if (/livesite$/.test(url)) {
            // 登录成功之后，回到登录页
            this.progressList[0].text = '登录成功，跳转中...'
            this.loadUrl('buyin', 'https://eos.douyin.com/livesite/marketing/lottery')
          }

          // 登录失败
          if (url.indexOf('https://www.douyinec.com/') > -1) {
            // 失败后需要调用这个log_out=1来清理一些缓存信息
            this.progressList[0].text = '登录失败，跳转中...'
            this.loadUrl('buyin', BUYIN_LOGIN_URL + '?log_out=1&type=24')
          }
        }

        this.loadUrl('buyin', EOS_LOGIN_URL)
      })
    },

    /**
     * 启动百应登录
     */
    startBuyinLogin() {
      return new Promise(async (resolve) => {
        if (!this.data.douyinid) {
          resolve({
            success: false,
            code: BUYIN_LOGIN_CODE.NO_BIND_DOUYIN
          })
          return
        }

        // if (!this.data.baiyingid) {
        //   resolve({
        //     success: false,
        //     code: BUYIN_LOGIN_CODE.NO_BIND_BUYIN
        //   })
        //   return
        // }

        this.isProgressPannelVisible = true

        // 打开百应窗口
        this.openBuyin()

        // 检查百应登录状态，尝试自动登录
        const eosUserInfo = await this.checkEosLogin()
        this.eosUserInfo = eosUserInfo

        // 设置商品关键词处理器
        // this.setProductKeywordsProcessor()

        // log(
        //   `抖音ID: ${douyinId} (${typeof douyinId}), 配置中的抖音ID: ${this.data.douyinid} (${typeof this.data
        //     .douyinid})`
        // )

        // if (!buyinId) {
        //   log('获取百应ID失败')
        //   this.progressList[0].state = 3
        //   this.progressList[0].text = '未开通百应后台'
        //   resolve({
        //     success: false,
        //     code: BUYIN_LOGIN_CODE.GET_BUYINID_FAILED
        //   })
        //   return
        // }
        // if (buyinId !== this.data.baiyingid) {
        //   this.isProgressPannelVisible = false
        //   log('百应ID不匹配')
        //   resolve({
        //     success: false,
        //     code: BUYIN_LOGIN_CODE.BUYINID_NOT_MATCH
        //   })
        //   return
        // }

        // 隐藏百应窗口
        this.hideView('buyin')

        // 完成登录流程
        this.progressList[0].state = 2
        this.progressList[0].text = '登录成功'

        // 开始执行小黄车铺货流程
        this.progressList[1].state = 1

        // 先判断小黄车中是否有商品，有就直接开播，没有就铺货
        // 铺货（buyin）:
        //    先获取配置中的计划，如果没有，抛出异常（只有一个计划，直接铺，两个或以上计划，我才跟配置做匹配）
        //    根据计划id获取商品列表
        //    根据商品列表去铺货
        // 启动弹车定时器(buyin):

        // 获取商品数量
        const productLen = await this.getProductsLen()
        log('获取商品列表: ' + productLen)

        if (productLen === 0) {
          // 铺货
          let result

          log('开始执行铺货')
          result = await this.pushProducts(this.data.plan)

          if (result.success) {
            log(`成功上架`)
            while (true) {
              const success_count = await this.getProductsLenAgain()
              log(`重新获取商品 ${result.msg} 件`)
              if (success_count == result.msg) {
                break
              } else {
                await sleep(3000)
              }
            }
            this.progressList[1].state = 2
            this.progressList[1].text = `成功上架 ${result.msg} 件商品`
            resolve({ success: true })
          } else {
            this.progressList[1].state = 3
            this.progressList[1].text = '铺货失败:' + result.msg
            resolve({
              success: false,
              code: BUYIN_LOGIN_CODE.BIND_PRODUCT_FAILED
            })
          }
        } else if (productLen < 0) {
          this.progressList[1].state = 3
          this.progressList[1].text = '获取商品列表失败:' + productLen
        } else {
          log('已经完成铺货，直接跳过')
          this.progressList[1].state = 2
          this.progressList[1].text = `已上架 ${productLen} 件商品`
          resolve({ success: true })
        }

        // 设置货盘商品回复话术
        // this.setProductTextList(this.productTextList)

        // 绑定umengid
        if (window.__bridge__ && window.__bridge__.onProfileSignIn) {
          log('上报umeng计划id及version', this.version)
          window.__bridge__.onProfileSignIn(this.data.id, this.version)
        }
      })
    },
    // 违规监控
    startViolationMonitor() {
      log('启动违规监控')
      window.__violation_callback__ = (data) => {
        log('直播中出现违规', data)
        this.emergencyBraking = data
        this.emergencyBraking.audio = window.__currentAudio__
        this.pushVioliationEvent(data)
        this.stopBroadCast()
      }
      this.exec(
        'buyin',
        (startBroadcastAt) => {
          clearInterval(window.__violationMonitorTimmer)
          window.__violationMonitorTimmer = setInterval(() => {
            window
              .fetch('/api/governance/creator/violations')
              .then((res) => {
                return res.json()
              })
              .then((data) => {
                if (!data || data.code !== 0 || !data.data || !data.data.violation_list) {
                  console.log('[startViolationMonitor] 获取违规列表失败')
                  return
                }
                var list = data.data.violation_list
                if (!list.length) {
                  return
                }
                var headData = list[0]
                if (headData.penalize_time * 1000 > startBroadcastAt) {
                  var tmp = {
                    reason: headData.violation_reason,
                    desc: headData.violation_desc,
                    penalize: headData.penalize_result.join(';'),
                    ts: headData.penalize_time * 1000,
                    video: ''
                  }
                  if (
                    headData.violation_content &&
                    headData.violation_content.video_list &&
                    Array.isArray(headData.violation_content.video_list) &&
                    headData.violation_content.video_list.length > 0
                  ) {
                    tmp.video = headData.violation_content.video_list[0].video_url
                  }
                  window.__bridge__.injectJs(
                    'render',
                    `javascript:window.__violation_callback__(${JSON.stringify(tmp)})`
                  )
                  clearInterval(window.__violationMonitorTimmer)
                }
              })
              .catch((err) => {
                console.log('[startViolationMonitor] 获取违规列表异常', err.message)
              })
          }, 10 * 1000)
        },
        this.startBroadcastAt
      )
    },
    // 定时引导
    startAutoGuide(_config) {
      const config = _config || this.getAutoGuideConfig()

      log('[AutoGuide] 开始执行定时引导', config)

      if (
        !config ||
        !config.params ||
        'boolean' !== typeof config.enable ||
        !config.params.hasOwnProperty('interval') ||
        'number' !== typeof config.params.interval ||
        !config.params.hasOwnProperty('text') ||
        !Array.isArray(config.params.text)
      ) {
        log('[AutoGuide] 定时引导配置不满足要求，终止')
        return
      }

      if (!config.enable) {
        log('[AutoGuide] 关闭定时引导')
        this.exec('buyin', () => {
          clearInterval(window.__guideTimmer)
        })
        return
      }

      log('[AutoGuide] 启动定时引导,间隔', config.params.interval / 1000, '秒')

      // 定时引导最小间隔10秒
      if (config.params.interval < 10 * 1000) {
        config.params.interval = 10 * 1000
      }

      this.exec(
        'buyin',
        (config) => {
          clearInterval(window.__guideTimmer)
          var textList = config.params.text || []
          var textIndex = 0

          console.log('[buyin:AutoGuide] 定时引导配置', JSON.stringify(config))

          window.__guideTimmer = setInterval(() => {
            if (!window.__is_living__) {
              return
            }

            if (textIndex >= textList.length) {
              textIndex = 0
            }

            var text = textList[textIndex++]

            window
              .fetch('/api/anchor/comment/operate', {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({
                  operate_type: 2,
                  content: text
                })
              })
              .then((res) => res.json())
              .then((data) => {
                if (data && data.code === 0) {
                  console.log('[buyin:AutoGuide] 定时引导发送成功:', text)
                } else {
                  console.log('[buyin:AutoGuide] 定时引导发送失败: ' + JSON.stringify(data))
                }
              })
              .catch((err) => console.log('[buyin:AutoGuide] 定时引导发送异常: ' + err.message))
          }, config.params.interval)
        },
        config
      )
    },
    // 直播状态监控
    startLivingMonitor() {
      log('启动直播状态监控')
      this.exec('buyin', () => {
        clearInterval(window.__livingTimmer)
        if ('boolean' !== typeof window.__is_living__) {
          window.__is_living__ = false
        }
        window.__livingTimmer = setInterval(() => {
          window
            .fetch('/api/anchor/livepc/playinfo')
            .then((res) => {
              return res.json()
            })
            .then((res) => {
              if (!res || res.code !== 0 || !res.data || !res.data.hasOwnProperty('server_time')) {
                return
              }
              const livingState = res.data.server_time > 0
              if (livingState !== window.__is_living__) {
                console.log('[buyin:LivingMonitor] 直播状态变更:', livingState)
              }
              window.__is_living__ = livingState
            })
            .catch(() => {})
        }, 5 * 1000)
      })
    },
    // 弹幕监控
    startCommentProcessor() {
      this.exec('buyin', () => {
        // 自动回复
        let cursor = ''
        let internal_ext = ''
        const matchKeywords = (comment, keywords) => {
          if (!comment || !keywords) {
            return false
          }

          let keywordsArray = keywords

          if (typeof keywords === 'string') {
            keywordsArray = keywords.split(/[\r\n;,.；，。\s]+/).map((i) => i.trim())
          }

          if (!keywordsArray.length) {
            return false
          }

          for (let i = 0; i < keywordsArray.length; i++) {
            if (comment.indexOf(keywordsArray[i]) > -1) {
              return true
            }
          }

          return false
        }

        window.__isRepeatCache = window.__isRepeatCache || {}

        const isRepeat = (text, expired = 30e3) => {
          const now = Date.now()
          // 清理一下内存数据，防止内存累积
          for (let key in __isRepeatCache) {
            if (__isRepeatCache[key] < now) {
              delete __isRepeatCache[key]
            }
          }
          // 检查是否存在
          if (__isRepeatCache.hasOwnProperty(text)) {
            return true
          } else {
            // 不存在就存入，并且设置过期时间
            __isRepeatCache[text] = now + expired
            return false
          }
        }

        // 注入函数
        window.__startCommentFetch = () => {
          console.log('[buyin:CommentFetch] 启动')
          clearInterval(window.__commentFetcherTimmer)

          window.__commentFetcherTimmer = setInterval(() => {
            if (!window.__is_living__) {
              return
            }

            let shouldAutoReply = false
            if (
              window.__autoReplyConfig &&
              window.__autoReplyConfig.enable &&
              window.__autoReplyConfig.params &&
              window.__autoReplyConfig.params.length > 0
            ) {
              shouldAutoReply = true
            }

            let shouldAutoForbid = false
            if (
              window.__autoForbidConfig &&
              window.__autoForbidConfig.enable &&
              window.__autoForbidConfig.params &&
              window.__autoForbidConfig.params.length > 0
            ) {
              shouldAutoForbid = true
            }

            console.log('[buyin:CommentFetch] 是否启用自动回复', shouldAutoReply, '是否启用自动禁言', shouldAutoForbid)

            if (!shouldAutoReply && !shouldAutoForbid) {
              // console.log('[buyin:CommentFetch] 自动回复 和 自动禁言 无需开启,不拉取弹幕')
              return
            }

            window
              .fetch(
                `/api/anchor/comment/info?comment_query_type=1&similar_comment_enable=true&request_source=0&cursor=${cursor}&internal_ext=${internal_ext}`
              )
              .then((res) => res.json())
              .then((data) => {
                if (!data || !data.data) {
                  cursor = ''
                  internal_ext = ''
                  console.log('[buyin:CommentFetch] 请求弹幕错误: ' + JSON.stringify(data))
                  return
                }

                var comment_infos = data.data.comment_infos || []

                // console.log('弹幕响应 >>', JSON.stringify(comment_infos))

                if (!comment_infos.length) {
                  return
                }

                // console.log(
                //   `[buyin:CommentFetch] 获取到的弹幕条数 ${comment_infos.length} cursor:${cursor}-${data.data.cursor} internal_ext: ${internal_ext}-${data.data.internal_ext}`
                // )

                cursor = data.data.cursor
                internal_ext = data.data.internal_ext

                for (var i = 0; i < comment_infos.length; i++) {
                  var comment = comment_infos[i]
                  console.log('[buyin:CommentFetch] 收到弹幕', comment.nick_name, comment.content)

                  // 不处理主播自己的弹幕
                  if (comment.uid === window.__origin_uid__) {
                    console.log('[buyin:CommentFetch] 不回复 主播自己弹幕')
                    continue
                  }

                  // 跳过重复的弹幕
                  if (isRepeat(comment.comment_id)) {
                    console.log('[buyin:CommentFetch] 不回复 重复内容')
                    continue
                  }

                  // 自动禁言
                  if (shouldAutoForbid && matchKeywords(comment.content, window.__autoForbidConfig.params)) {
                    console.log('[buyin:CommentFetch] 命中禁言关键词', JSON.stringify(window.__autoForbidConfig.params))
                    window
                      .fetch('/api/anchor/comment/operate', {
                        method: 'POST',
                        headers: { 'content-type': 'application/json' },
                        body: JSON.stringify({
                          operate_type: 4,
                          uid: comment.uid,
                          content: comment.content,
                          nick_name: comment.nick_name,
                          comment_id: comment.comment_id
                        })
                      })
                      .then((res) => res.json())
                      .then((data) => {
                        if (data && data.code === 0) {
                          console.log('[buyin:CommentFetch] 禁言成功')
                        } else {
                          console.log('[buyin:CommentFetch] 禁言失败', JSON.stringify(data))
                        }
                      })
                      .catch((err) => console.log('[buyin:CommentFetch] 禁言异常', err.message))
                    return
                  }

                  // 自动回复
                  if (shouldAutoReply) {
                    var replyContent

                    // console.log(`[buyin:CommentFetch] 开始执行自动回复`)
                    // console.log(`[buyin:CommentFetch] productTextList`, JSON.stringify(window.__productTextList))
                    // console.log(`[buyin:CommentFetch] products`, JSON.stringify(window.__products))
                    // console.log(`[buyin:CommentFetch] __matchProductKeywords`, typeof window.__matchProductKeywords)

                    if (window.__productTextList && window.__productTextList.length && window.__matchProductKeywords) {
                      console.log(`[buyin:CommentFetch] 开始匹配 货盘商品关键词 `, comment.content, comment.nick_name)
                      replyContent = window.__matchProductKeywords(
                        comment.content,
                        comment.nick_name,
                        window.__products,
                        window.__productTextList
                      )
                      if (replyContent) {
                        console.log('[buyin:CommentFetch] 命中 货盘商品关键词')
                      }
                    }

                    if (!replyContent) {
                      console.log(`[buyin:CommentFetch] 开始匹配 自动回复关键词`)
                      for (
                        var autoReplyConfigIndex = 0, len = window.__autoReplyConfig.params.length;
                        autoReplyConfigIndex < len;
                        autoReplyConfigIndex++
                      ) {
                        var replyConfig = window.__autoReplyConfig.params[autoReplyConfigIndex]
                        var keywords = replyConfig.keywords
                        var reply = replyConfig.reply

                        if (!matchKeywords(comment.content, keywords)) {
                          continue
                        }

                        if (typeof reply === 'string') {
                          reply = [reply]
                        }

                        console.log('[buyin:CommentFetch] 命中 自动回复关键词', JSON.stringify(keywords))

                        if (!Array.isArray(reply)) {
                          continue
                        }

                        var index = Math.floor(reply.length * Math.random())

                        replyContent = (reply[index] || '').trim()

                        if (!replyContent) {
                          continue
                        }

                        replyContent = '@' + comment.nick_name + ' ' + replyContent

                        break
                      }
                    }

                    // 如果“商品关键词”和“自动回复”都没匹配到，终止本次匹配
                    if (!replyContent) {
                      return
                    }

                    console.log('[buyin:CommentFetch] 准备发送回复内容', replyContent)

                    if (isRepeat(replyContent)) {
                      console.log('[buyin:CommentFetch] 终止发送 回复内容重复')
                      continue
                    }

                    window
                      .fetch('/api/anchor/comment/operate', {
                        method: 'POST',
                        headers: { 'content-type': 'application/json' },
                        body: JSON.stringify({
                          operate_type: 1,
                          content: replyContent,
                          comment_id: comment.comment_id
                        })
                      })
                      .then((res) => res.json())
                      .then((data) => {
                        if (data && data.code === 0) {
                          console.log('[buyin:CommentFetch] 自动回复成功')
                        } else {
                          console.log('[buyin:CommentFetch] 自动回复失败', JSON.stringify(data))
                        }
                      })
                      .catch((err) => console.log('[buyin:CommentFetch] 自动回复异常' + err.message))
                  }
                }
              })
              .catch((err) => {
                cursor = ''
                internal_ext = ''
                console.log('[buyin:CommentFetch] 请求弹幕异常', err.message)
              })
          }, 2e3)
        }
        // 启动
        window.__startCommentFetch()
      })
    },
    /**
     * 获取已上架商品数量，并且注册小黄车任务
     * 返回值：负数代表异常
     */
    getProductsLen() {
      log('开始获取商品数量')
      return new Promise((resolve) => {
        window.__products_size_callback__ = (len) => {
          // this.setProductList(this.products)
          resolve(len)
        }
        this.exec('buyin', () => {
          // 自动弹车
          window.__startExplain = (delay, list = [], a = 1, b = 1) => {
            console.log('[startExplain] 启动自动弹车任务', window.__products && window.__products.length)
            clearInterval(window.__explainTimmer)
            window.__explainIndex = 0
            if (window.__room_id__ && window.__products && window.__products.length) {
              console.log('[startExplain] 启动自动弹车任务, 商品数据量: ' + window.__products.length)
              window.__explainTimmer = setInterval(() => {
                let index = window.__explainIndex++
                console.log('[startExplain] 当前索引:', index)
                if (list.length) {
                  const offset = list[0] === 0 ? 0 : 1 // 考虑到有人配置索引从0开始，有人从1开始
                  index = Math.max(list[index % list.length] - offset, 0)
                  console.log('[startExplain] 配置索引:', index)
                }
                index = index % __products.length
                console.log('[startExplain] 实际索引:', index)
                const product = __products[index]
                if (product) {
                  var postData = JSON.stringify({
                    card_id: product.card_id,
                    room_id: window.__room_id__,
                    operation: 3,
                    source: product.auth_type
                  })
                  console.log(
                    '[startExplain] 发起弹窗请求: ' +
                      postData +
                      '  ' +
                      product.card_id +
                      ', 执行第' +
                      (index + 1) +
                      '个商品'
                  )
                  window
                    .fetch('/data/life/live/card/switch/', {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: postData
                    })
                    .then((res) => res.json())
                    .then((res) => {
                      if (res && res.status_code === 0) {
                        console.log('[startExplain] 小黄车弹窗请求:成功 ' + product.card_id)
                      } else {
                        console.log('[startExplain] 小黄车弹窗请求:失败 ' + res.msg || '')
                      }
                    })
                    .catch((err) => {
                      console.log('[startExplain] 小黄车弹窗请求:异常 ' + err.message)
                    })
                }
              }, delay * 1000)
            } else {
              console.log('[startExplain] 没有商品，尝试获取')
              // 如果没有全局变量，就尝试获取
              window
                .fetch(
                  '/data/life/live/agg/card/detail/?agg_card_id=0&room_id=' +
                    (window.__room_id__ || 0) +
                    '&anchor_id=' +
                    window.__eos_user_info__.account_id
                )
                .then((res) => res.json())
                .then((data) => {
                  if (data && 0 === data.status_code) {
                    window.__products = data.card_list
                    console.log('[startExplain] 获取到', window.__products.length, '个商品')
                    clearTimeout(window.__retryTimer)
                    window.__startExplain(delay, list, 1, 1)
                  } else {
                    console.log('自动弹车失败:' + ((data && data.msg) || JSON.stringify(data)))
                    clearTimeout(window.__retryTimer)
                    window.__retryTimer = setTimeout(() => window.__startExplain(delay, list, b, a + b), a * 1000)
                  }
                })
                .catch((err) => {
                  console.log('自动弹车失败:' + err.message)
                  clearTimeout(window.__retryTimer)
                  window.__retryTimer = setTimeout(() => window.__startExplain(delay, list, b, a + b), a * 1000)
                })
            }
          }

          // 停止自动弹车
          window.__stopExplain = () => {
            console.log('[stopExplain] 停止自动弹车任务, 商品数据量: ', window.__products && window.__products.length)
            clearInterval(window.__explainTimmer)
          }

          // 获取已上架商品数量
          window.__getProducts = () => {
            window
              .fetch(
                '/data/life/live/agg/card/detail/?agg_card_id=0&room_id=' +
                  (window.__room_id__ || 0) +
                  '&anchor_id=' +
                  window.__eos_user_info__.account_id
              )
              .then((res) => res.json())
              .then((data) => {
                console.log('[getProductsLen] json:' + JSON.stringify(data))
                if (data && 0 === data.status_code) {
                  console.log('[getProductsLen] 获取到' + data.total + '个商品')
                  window.__products = data.card_list
                  window.__bridge__.injectJs(
                    'render',
                    'javascript:window.__products_size_callback__(' + data.total + ')'
                  )
                } else {
                  console.log('[getProductsLen] 获取商品列表异常: -1')
                  // window.__bridge__.injectJs('render', 'javascript:window.__products_size_callback__(-1)')
                  clearTimeout(window.__getProductsTimer)
                  window.__getProductsTimer = setTimeout(() => __getProducts(b, a + b), a * 1000)
                }
              })
              .catch(() => {
                console.log('[getProductsLen] 获取商品列表异常: -2')
                clearTimeout(window.__getProductsTimer)
                window.__getProductsTimer = setTimeout(() => __getProducts(b, a + b), a * 1000)
              })
          }

          // 执行调用
          window.__getProducts()

          // 延长登录时间
          clearInterval(window.__authTimmer)
          window.__authTimmer = setInterval(() => {
            console.log('[auth] start')
            window
              .fetch('/data/life/live/user/info/v1/')
              .then((res) => res.json())
              .then((data) => console.log('[auth] response', data.msg))
              .catch((err) => console.log('[auth] err', err.message))
          }, 5 * 60 * 1000)
        })
      })
    },
    // 再次获取已上架商品数量
    getProductsLenAgain() {
      return new Promise((resolve) => {
        window.__products_size_callback__ = (len) => {
          // this.setProductList(this.products)
          resolve(len)
        }
        this.exec('buyin', () => {
          window.__getProducts && window.__getProducts()
        })
      })
    },
    openBuyin() {
      const dpr = window.devicePixelRatio
      const size = {
        width: 300,
        height: 260
      }
      this.showView(
        'buyin',
        ((document.body.clientWidth - size.width) / 2) * dpr,
        150,
        size.width * dpr,
        size.height * dpr
      )
    },
    exec(viewId, fn, ...args) {
      if (!Bridge) {
        return
      }
      const code = `(${fn.toString()}).apply(null, ${JSON.stringify(args)})`
      Bridge.injectJs(viewId, `javascript:${encodeURIComponent(code)}`)
    },
    loadUrl(viewId, url) {
      log('载入网页', viewId, url)
      Bridge.loadUrl(viewId, url, (window.devicePixelRatio - 1) * 100)
    },
    showView(viewId, left, top, width, height) {
      if (!Bridge) {
        return
      }
      log('显示窗口 =>', arguments)
      Bridge.showWindow(viewId, left, top, width, height)
    },
    hideView(viewId) {
      if (!Bridge) {
        return
      }
      log('隐藏窗口 =>', viewId)
      try {
        Bridge.hideWindow(viewId)
      } catch (err) {
        log('隐藏窗口失败', viewId, err.message)
      }
    },
    async pushVioliationEvent(data) {
      log('推送违规消息')
      this.$http.post(`/api/devices/${this.id}/wg-messages`, data)
    },
    async pushBroadcastEvent(eventName) {
      let apiUrl
      console.log('pushBroadcastEvent', eventName)
      switch (eventName) {
        case 'start':
          apiUrl = `/api/devices/${this.id}/start-messages`
          break
        case 'stop':
          apiUrl = `/api/devices/${this.id}/stop-messages`
          break
      }
      log('推送直播状态消息', eventName, apiUrl)
      try {
        await this.$http.post(apiUrl)
        log('推送直播状态成功')
      } catch (err) {
        log('推送直播状态失败', err.message)
      }
    },
    async fetchProducts() {
      try {
        const res = await this.$http.get(`/api/devices/${this.id}/huopan`)
        this.products = res.data.slice(0, 100)
        log('获取货盘商品成功', this.products.length)
      } catch (err) {
        log('获取货盘商品异常', err.message)
      }
    },
    async fetchProductText() {
      try {
        const res = await this.$http.get('/api/product-text')
        this.productTextList = res.data.list
        log('获取商品话术成功', res.data.list)
      } catch (err) {
        log('获取商品话术异常', err.message)
      }
    },
    async fetch() {
      let data
      console.log(
        '开始获取配置',
        localStorage.getItem('xiaozhumao_token'),
        localStorage.getItem('xiaozhumao_device_id')
      )
      try {
        let apiUrl = `/live-configs/${this.id}`
        if (/^\d+$/.test(this.id)) {
          apiUrl = `/api/devices/${this.id}/strapi-live-config`
        }
        const res = await this.$http.get(apiUrl)
        data = res.data
        log(res.status)
        log('获取到计划配置', res.data)
        if (Bridge && Bridge.setLiveId) {
          log('给app设置liveId', res.data.id)
          Bridge.setLiveId(res.data.id)
        }
      } catch (err) {
        Notify({
          message: `配置 ${this.id} 获取失败`,
          type: 'danger'
        })
        return
      }

      await this.fetchProducts()
      await this.fetchProductText()

      const localAudioEnable = JSON.parse(localStorage.getItem('xiaozhumao_audio_enable'))
      const localBgmEnable = JSON.parse(localStorage.getItem('xiaozhumao_bgm_enable'))

      data.item.forEach((item) => {
        if ('audio' === item.type) {
          this.hasAudio = true
          if (null !== localAudioEnable) {
            item.enable = localAudioEnable
          }
          if (item.enable) {
            this.audioManualSwitch = item.enable
          }
        } else if ('bgm' === item.type) {
          this.hasBgm = true
          if (null !== localBgmEnable) {
            item.enable = localBgmEnable
          }
          if (item.enable) {
            this.bgmManualSwitch = item.enable
          }
        }
      })
      this.data = Object.assign({}, data)
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
