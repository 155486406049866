<template>
  <div class="app-component app-component_camera" :style="{
    ...style
  }"></div>
</template>
<script>
import event from '@/event'
import { get as getProps } from 'lodash';
import ChromaKeyVideo from '@/libs/ChromaKeyVideo'

const Bridge = window.__bridge__ || null

export default {
  props: {
    data: {
      type: Object,
      default: {}
    },
    state: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      style: {
        chromaKeySettings: null
      }
    }
  },
  created() {
    const defaultVal = {
      color: '#04f404',
      similarity: 0,
      smoothness: 0
    }

    this.chromaKeySettings = getProps(this.data, 'chromakey', defaultVal) || defaultVal

    log('camera chromeKeySetting =>', this.chromaKeySettings)
  },
  mounted() {
    this.style = {
      top: this.$getMeta('top'),
      right: this.$getMeta('right'),
      bottom: this.$getMeta('bottom'),
      left: this.$getMeta('left'),
      width: this.$getMeta('width'),
      height: this.$getMeta('height')
    }
    this.createCamera()

    event.$on('app:resume', () => {
      console.log('[Camera] resume trigger')
      this.resetCamera()
    })

  },
  methods: {
    async resetCamera() {

      if (!this.chromaKeyVideo) {
        return
      }

      if (this.stream) {
        this.stream.getTracks().forEach(function (track) {
          track.stop()
        });
        this.stream = null
      }

      let stream

      try {
        stream = await window.navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            // facingMode: 'environment'
            facingMode: 'user'
          }
        })
      } catch (err) {
        log('重新获取摄像头失败', err.toString())
      }


      if (!stream) {
        return
      }

      log('重新获取摄像头成功')

      this.stream = stream

      this.video.pause()
      this.video.srcObject = stream

      await this.$nextTick()

      this.video.srcObject = stream
      this.video.play()

    },
    async createCamera() {
      if (!Bridge) {
        return
      }

      const permission = Bridge.checkPermissions()
      let hasCamera = permission.camera

      if (!hasCamera) {
        hasCamera = await new Promise((resolve) => {
          event.$once('permission:camera', (state) => {
            log('权限回调', state)
            resolve(state)
          })
          Bridge.requirePermission(['camera'])
        })
      }

      if (!hasCamera) {
        log('未获得摄像头权限')
        return
      }

      log('已获得摄像头权限')

      let stream

      try {
        stream = await window.navigator.mediaDevices.getUserMedia({
          video: {
            // facingMode: 'environment'
            facingMode: 'user'
          }
        })
      } catch (err) {
        log('获取摄像头失败', err.toString())
      }

      this.stream = stream

      await this.$nextTick()

      this.chromaKeyVideo = new ChromaKeyVideo({
        container: this.$el,
        ...this.chromaKeySettings
      })

      this.video = this.chromaKeyVideo.video

      this.video.srcObject = stream

      this.video.play()


    }
  }
}
</script>
<style lang="scss" scoped>
.app-component_camera {
  position: absolute;

  ::v-deep {
    canvas {
      transform: scaleX(-1);
      position: absolute;
      box-sizing: border-box;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: transparent;
    }
  }
}
</style>
