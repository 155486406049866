<template>
  <div
    class="app-component app-component_image"
    :style="{
      ...meta
    }"
  >
    <template v-if="data && data.source && data.source.length > 0">
      <img :src="data.source[currentIndex].url" />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      currentIndex: 0
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.currentIndex = 0
      }
    }
  },
  created() {
    this.timmer = null
    const rotate = this.$getMeta('rotate') || 0
    this.meta = {
      top: this.$getMeta('top'),
      right: this.$getMeta('right'),
      bottom: this.$getMeta('bottom'),
      left: this.$getMeta('left'),
      width: this.$getMeta('width'),
      height: this.$getMeta('height'),
      transform: `scale(${this.$getMeta('scale')}) rotate(${rotate}deg)`,
      transformOrigin: 'top left'
    }
  },
  mounted() {
    this.runSchedule()
  },
  beforeDestroy() {
    clearInterval(this.timmer)
  },
  methods: {
    runSchedule() {
      this.timmer = setInterval(() => {
        if (this.currentIndex >= this.data.source.length - 1) {
          this.currentIndex = 0
        } else {
          this.currentIndex++
        }
      }, (this.data.duration || 5) * 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-component_image {
  position: absolute;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
