<template>
  <div
    class="app-component app-component_media"
    :style="{
      ...style
    }"
  ></div>
</template>

<script>
import getProps from 'lodash/get'
import shuffle from 'lodash/shuffle'
import event from '@/event'
import ChromaKeyVideo from '@/libs/ChromaKeyVideo'

function dateDiff(d1, d2) {
  let diff = (d1.getTime() - d2.getTime()) / 1000
  diff = diff / (60 * 60)
  return Math.abs(Math.round(diff))
}

export default {
  props: {
    data: {
      type: Object,
      default: {}
    },
    state: {
      type: Number,
      default: 1
    }
  },
  data() {
    const STORE_KEY = this.data.type + '__media_index__'
    const mediaIndex = localStorage.getItem(STORE_KEY)

    if (mediaIndex !== null && ['audio', 'bgm'].indexOf(this.data.type) !== -1) {
      this.setIndex(parseInt(mediaIndex))
      localStorage.removeItem(STORE_KEY)
    }

    return {
      style: {},
      looped: 0,
      list: [],
      media: null,
      chromaKeySettings: null
    }
  },
  beforeDestroy() {
    if (this.chromaKeyVideo) {
      this.chromaKeyVideo.destroy()
      this.chromaKeyVideo = null
    }
    if (this.$mediaNode) {
      this.$mediaNode.src = ''
      this.$mediaNode = null
    }
  },
  created() {
    this.$mediaNode = null
    this.$canvas = null
    this._lastLogAt = 0
    this._lastTimestamp = 0

    const defaultVal = {
      color: '#04f404',
      similarity: 0,
      smoothness: 0
    }

    this.chromaKeySettings = getProps(this.data, 'chromakey', defaultVal) || defaultVal
    const scale = this.$getMeta('scale')
    const rotate = this.$getMeta('rotate') || 0
    this.style = {
      top: this.$getMeta('top'),
      right: this.$getMeta('right'),
      bottom: this.$getMeta('bottom'),
      left: this.$getMeta('left'),
      width: this.$getMeta('width'),
      height: this.$getMeta('height'),
      transform: `scale3d(${scale}, ${scale}, 1) rotate(${rotate}deg)`, // 利用3d渲染实现gpu调用，解决body拉伸问题. by fouber
      transformOrigin: 'top left'
    }
  },
  async mounted() {
    if (!this.data.enable) {
      return
    }

    if (!this.data.source || !this.data.source.length) {
      return
    }

    if ('random' === this.data.order) {
      this.data.source = shuffle(this.data.source)
    }

    switch (this.data.type) {
      case 'video':
        this.chromaKeyVideo = new ChromaKeyVideo({
          container: this.$el,
          ...this.chromaKeySettings
        })
        this.$mediaNode = this.chromaKeyVideo.video
        break
      case 'bgm':
      case 'audio':
        this.$mediaNode = document.createElement('audio')
        break
    }

    this.$mediaNode.preload = 'auto'
    this.$mediaNode.autoplay = false

    // 如果只有一个视频元素loop，就用video的loop属性，会更连贯，不会出现闪烁问题
    // 使用loop之后，视频的ended方法不会触发，需要注意一下
    // 如果需要关注每次循环的开始，可以利用timeupdate方法，监控currentTime==0的时候
    if (
      this.data &&
      this.data.type == 'video' &&
      this.data.loop == 0 &&
      this.data.source &&
      this.data.source.length === 1
    ) {
      this.$mediaNode.loop = true
    }

    this.$mediaNode.addEventListener('error', this.handleError.bind(this))
    this.$mediaNode.addEventListener('stalled', this.handleStalled.bind(this))
    this.$mediaNode.addEventListener('ended', this.handleEnded.bind(this))
    this.$mediaNode.addEventListener('timeupdate', this.handleTimeUpdate.bind(this))

    event.$on('voicePlayStart', () => {
      if (['audio', 'bgm'].indexOf(this.data.type) === -1 || !this.$mediaNode) {
        return
      }
      log('[media] 触发voicePlayStart')
      this.previousAudioVolume = this.$mediaNode.volume
      log('[media] 保存音量', this.previousAudioVolume)
      this.$mediaNode.volume = this.previousAudioVolume * 0.3
    })

    event.$on('voicePlayEnd', () => {
      if (['audio', 'bgm'].indexOf(this.data.type) === -1 || !this.$mediaNode || !this.previousAudioVolume) {
        return
      }
      if ('audio' !== this.data.type || !this.$mediaNode || !this.previousAudioVolume) {
        return
      }
      log('[media] 触发voicePlayEnd')
      log('[media] 触发voicePlayEnd 设置音量', this.previousAudioVolume)
      this.$mediaNode.volume = this.previousAudioVolume
    })

    event.$on('mediaUpdate', (data) => {
      if (!data) {
        return
      }
      const { id, enable, value } = data
      if (!this.$mediaNode || id !== this.data.id) {
        return
      }
      log(`[media] 遥控器 媒体更新`)
      if (enable) {
        log(`[media] 遥控器 播放`)
        this.$mediaNode.play()
      } else {
        log(`[media] 遥控器 暂停`)
        this.$mediaNode.pause()
      }
      log(`[media] 遥控器 音量 ${value}`)
      this.$mediaNode.volume = value
    })

    event.$on('app:resume', () => {
      if (!this.$mediaNode || 1 !== this.state) {
        log(`触发resume${this.data.type},没有媒体或者未直播状态,不播放`)
        return
      }
      log(`触发resume${this.data.type},播放媒体`)

      if (this.$mediaNode) {
        this.$mediaNode.play()
      }
    })

    event.$on('reset', () => {
      log(`${this.data.type}触发reset`)
      this.play()
    })

    event.$on('start', () => {
      log(`${this.data.type}触发start`)
      this.play()
      if (this.chromaKeyVideo && !this.chromaKeyVideo.running) {
        this.chromaKeyVideo.startDraw()
      }
    })

    event.$on('stop', () => {
      if (this.data.index === this.data.source.length - 1) {
        this.setIndex(0)
      } else {
        this.setIndex(this.data.index + 1)
      }
    })

    log(`${this.data.type} ${this.data.label} 初始化完成`)
  },
  methods: {
    setIndex(_index) {
      if ('video' === this.data.type) {
        return
      }
      let index = _index
      if (index >= this.data.source.length) {
        index = 0
      }
      log(`${this.data.type} ${this.data.label} 索引设置为 ${index}`)
      this.$emit('indexUpdate', index)
    },
    retry() {
      if (this._isRetrying) {
        console.log('[RETRY:' + this.data.id + '] isRetrying, return')
        return
      }
      this._isRetrying = true
      console.log('[RETRY:' + this.data.id + '] isRetrying = true')
      // 超时20秒，允许重试
      clearTimeout(this._retryTimer)
      this._retryTimer = setTimeout(() => {
        console.log('[RETRY:' + this.data.id + '] abort _retryTimer')
        this._isRetrying = false
      }, 20e3)

      // 记录并还原currentTime位置
      const currentTime = this.$mediaNode.currentTime
      console.log('[RETRY:' + this.data.id + '] currentTime:', currentTime, ', index:', this.data.index)
      log(`准备重试 类型 ${this.data.type} 标签 ${this.data.label} 当前索引${this.data.index} 时间戳:${currentTime}`)
      if (this.chromaKeyVideo) {
        // 这里尝试减少一些性能开销，卡住的时候没有必要一直draw
        console.log('[RETRY:' + this.data.id + '] chromaKeyVideo stopDraw')
        this.chromaKeyVideo.stopDraw()
      }
      console.log('[RETRY:' + this.data.id + '] set media src', this.media.url)
      this.$mediaNode.src = `${this.media.url}?v=retry` // by zhangyunlong 绕过本地缓存，临时的改动
      this.$mediaNode
        .play()
        .catch((e) => console.log('[RETRY:' + this.data.id + '] play error', e.message, e))
        .then(() => console.log('[RETRY:' + this.data.id + '] play then'))
        .finally(() => console.log('[RETRY:' + this.data.id + '] play finally'))
      // 测试了一下，这里如果赋值当前的currentTime，会跳过几帧，从而触发新的视频地址的timeupdate事件
      // 应该设置上一次的currentTime，就不会绕过我在timeupdate事件里的判断了
      this.$mediaNode.currentTime = currentTime
      console.log('[RETRY:' + this.data.id + '] set currentTime', currentTime)
    },
    // 检查资源是否还活着
    checkLive() {
      if (0 === this.state) {
        return
      }
      // 轮询检查媒体播放状态
      this.mediaTime = Date.now()
      clearInterval(this._checkTimer)
      this._checkTimer = setInterval(() => {
        const now = Date.now()
        if (now - this.mediaTime > 2000) {
          log(`媒体可能卡住了 ${this.data.type} ${this.data.label} ${this.data.index}`)
          this.retry()
        }
      }, 2000)
    },
    async play() {
      log(`开始播放 类型 ${this.data.type} 标签 ${this.data.label} 当前索引${this.data.index}, 资源id: ${this.data.id}`)
      // 在后台调整媒体数量之后，有可能会引发下标越界。
      if (this.data.index >= this.data.source.length) {
        this.setIndex(0)
        this.data.index = 0
      }
      this.media = this.data.source[this.data.index]

      if (this.data.type !== 'video') {
        if (!this.data.volume) {
          this.$mediaNode.muted = true
        } else {
          this.$mediaNode.muted = false
        }
        this.$mediaNode.volume = this.data.volume || 0
      }

      const handleCanplay = async () => {
        // 音频随机seek，持续时间前一段10%的范围内的随机时间
        log(`触发canplay, type: ${this.data.type}, label: ${this.data.label}, index: ${this.data.index}`)
        if (this.data.type === 'audio') {
          const duration = this.$mediaNode.duration
          const currentTime = Math.random() * (duration * 0.1)
          log(`讲解音频持续时间 ${duration}秒 seek到 ${currentTime}秒`)
          this.$mediaNode.currentTime = currentTime
          this._lastTimestamp = currentTime
          window.__currentAudio__ = {
            id: this.media.id,
            index: this.data.index,
            url: this.media.url,
            // 音频名称
            name: this.media.name,
            // 音频当前播放时长
            time: this.$mediaNode.currentTime,
            // 音频已经使用时长
            useTime: dateDiff(new Date(this.media.createdAt), new Date())
          }
        }
        this.$mediaNode.play()
        document.documentElement.addEventListener('click', () => this.$mediaNode.play())
        if (this.chromaKeyVideo && !this.chromaKeyVideo.running) {
          // 做一个判断，降低性能高开销
          log('>>> chromaKeyVideo startDraw')
          this.chromaKeyVideo.startDraw()
        }
      }

      this.$mediaNode.src = this.media.url
      this.$mediaNode.addEventListener('canplay', handleCanplay, {
        once: true
      })
      this.checkLive()
    },
    handleError(e) {
      const code = (e && e.currentTarget && e.currentTarget.error && e.currentTarget.error.code) || -1

      const errMsg = ['媒体出现错误']

      errMsg.push('type:' + this.data.type)

      if (this.media) {
        errMsg.push('sourceId:' + this.media.id)
        errMsg.push('url:' + this.media.url)
      }

      if (this.$mediaNode) {
        errMsg.push('currentTime:' + this.$mediaNode.currentTime)
        if (this.$mediaNode.error) {
          errMsg.push('errorCode:' + this.$mediaNode.error.code)
          errMsg.push('errMessage:' + (this.$mediaNode.error.message || ''))
        }
      }

      log(errMsg.join(' '))

      // 媒体出现错误并且媒体对象的 error.code === 3 表示编码错误
      // 参见: https://developer.mozilla.org/en-US/docs/Web/API/MediaError/code#media_error_code_constants
      if (this.$mediaNode && 3 === code) {
        log('媒体编码错误')
        this.$emit('decodeError', {
          type: this.data.type,
          time: this.$mediaNode.currentTime,
          id: this.media.id
        })
      }
      log(
        `触发error事件, type: ${this.data.type}, currentTime: ${this.$mediaNode.currentTime}, id: ${this.data.id}, code: ${code}`
      )
    },
    handleStalled() {
      log(`触发stalled事件, type: ${this.data.type}, currentTime: ${this.$mediaNode.currentTime}, id: ${this.data.id}`)
    },
    handleEnded() {
      // 清除检查
      clearTimeout(this._retryTimer)
      this._isRetrying = false
      this._lastTimestamp = 0
      log(`播放完成, 类型: ${this.data.type}, 标签: ${this.data.label}, 索引: ${this.data.index}, id: ${this.data.id}`)
      if ('audio' === this.data.type && 1 === this.state) {
        log('触发audio:ended')
        event.$emit('audio:ended')
        return
      }

      if (this.data.loop > 0) {
        this.looped++
        log(`媒体播放循环次数 ${this.looped} 总数 ${this.data.loop}`)
        if (this.looped === this.data.loop) {
          log('媒体循环次数已满，停止播放')
          clearInterval(this._checkTimer)
          return
        }
      }

      if (this.data.index === this.data.source.length - 1) {
        if ('random' === this.data.order) {
          this.data.source = shuffle(this.data.source)
        }
        this.setIndex(0)
      } else {
        this.setIndex(this.data.index + 1)
      }

      this.play()
    },
    handleTimeUpdate() {
      if (Date.now() - this._lastLogAt > 5e3) {
        log(
          '[TIMEUPDATE] itemId:',
          this.data.id,
          ', sourceId:',
          this.media.id,
          ', lastTimestamp:',
          this._lastTimestamp,
          ', currentTime:',
          this.$mediaNode.currentTime,
          ', type:',
          this.data.type,
          ', index:',
          this.data.index
        )
        if (this.$mediaNode) {
          this.$emit('timeUpdate', {
            id: this.data.id,
            duration: this.$mediaNode.duration,
            currentTime: this.$mediaNode.currentTime
          })
        }
        this._lastLogAt = Date.now()
      }

      if (this.data.type === 'audio' && this.$mediaNode && window.__currentAudio__) {
        window.__currentAudio__['time'] = this.$mediaNode.currentTime
      }

      // 在移动端，不管是不是卡住，这个时间会一直触发，所以要判断前后时间是否相同
      if (this._lastTimestamp !== this.$mediaNode.currentTime) {
        // 这里很关键，只有两次时间戳不同，才认为是在播放的
        // 网络卡住的时候，timeupdate其实会一直触发，只不过每次的时间戳都一样
        this._lastTimestamp = this.$mediaNode.currentTime
        this.mediaTime = Date.now()
        // 如果停了，就start
        if (this.chromaKeyVideo && !this.chromaKeyVideo.running) {
          log('>>> chromaKeyVideo timeupdae startDraw')
          this.chromaKeyVideo.startDraw()
        }

        // 清理一下retry的数据
        clearTimeout(this._retryTimer)
        this._isRetrying = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-component_media {
  position: absolute;
  overflow: hidden;

  ::v-deep {
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 0px;
      height: 0px;
      object-fit: cover;
    }

    canvas {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      box-sizing: border-box;
    }
  }
}
</style>
