import VueRouter from 'vue-router'

import LivePage from './pages/live'
import LiveEosPage from './pages/live-eos'
import LoginPage from './pages/login'
import BindPage from './pages/bind'

const routes = [
  { path: '/login', name: 'Login', component: LoginPage },
  { path: '/live/:id', name: 'Live', component: LivePage },
  { path: '/live-eos/:id', name: 'LiveEos', component: LiveEosPage },
  { path: '/bind', name: 'Bind', component: BindPage }
]

export default new VueRouter({
  mode: 'history',
  routes
})
